import { modalContentFromKeyPrefix } from 'aos-helpers/src/helpers/ModalContentBuilder'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentUserSelector } from 'aos-services/src/core/auth/state'
import { AosChecklistFilter } from 'aos-services/src/services/checklists/types/AosChecklist'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import { AosChecklistTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { mapChecklistUnitToUserRole } from 'aos-services/src/services/checklists/types/AosChecklistUnits'
import {
    AosUnitTaskItem,
    AosUnitTaskTemplate,
    isAosUnitTaskItem,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { ChangeSeqVector } from 'aos-services/src/services/checklists/types/input/ChangeSeqVector'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { ResizableSplittedPanel } from 'aos-ui/src/components/container/ResizableSplittedPanel'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { kebabCase } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { showActionModalAction } from '../../core/actionModal/actions'
import {
    applyItemSeqChangesAction,
    changeItemSeqAction,
    createTaskOrDescriptionActions,
    deleteItemAction,
    editMessageTemplateActions,
    editTaskOrDescriptionActions,
    reviewedChecklistTemplateAction,
} from '../../core/checklistManager/actions'
import {
    showCreateUnitTaskItemModalAction,
    showEditUnitTaskTemplateModalAction,
} from '../../core/checklistManager/unit/actions'
import { resizeColumnActionFactory } from '../../core/layout/actions'
import { State } from '../../core/state'
import { ChecklistItemsSection } from './checklist/partials/ChecklistItems'
import { ChecklistTemplateStatus } from './checklist/partials/ChecklistTemplateStatus'
import { UnitTaskItemSection } from './unitTask/partials/UnitTaskItems'

export const ChecklistTemplateContent = () => {
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector)
    const columnSizes = useSelector((state: State) => state.layout.columnSizes.checklistManager)
    const { selectedItemFromTemplate, selectedTemplate } = useSelector(
        (state: State) => state.checklistManager,
    )
    const { headerFilter } = useSelector((state: State) => state.checklistManager)

    const onDelete = (payload: AosChecklistItem | AosUnitTaskItem) => {
        const keyPrefix = `checklist-manager.delete-${kebabCase(
            isAosUnitTaskItem(payload) ? 'tasklist' : payload.type,
        )}`
        dispatch(
            showActionModalAction({
                ...modalContentFromKeyPrefix(keyPrefix),
                svg: SvgIcon.DeleteRound,
                onClickAction: () => dispatch(deleteItemAction([selectedTemplate!, payload])),
            }),
        )
    }

    const isEditable = useMemo(() => {
        const role =
            mapChecklistUnitToUserRole[(selectedItemFromTemplate as AosChecklistTemplate)?.unit]
        if (selectedItemFromTemplate) {
            if (role === currentUser.unitRole || headerFilter === AosChecklistFilter.UnitTasks) {
                return true
            }
            if (headerFilter === AosChecklistFilter.TaskChecklist) {
                return currentUser.isApoc() || currentUser.canEditTasks()
            }
        }
        return false
    }, [selectedItemFromTemplate, currentUser, headerFilter])

    const checklistName = useMemo(
        () =>
            selectedItemFromTemplate && headerFilter === AosChecklistFilter.EventChecklist
                ? (selectedItemFromTemplate as AosChecklistTemplate).name
                : translate('checklist-manager.templates.header-title'),
        [selectedItemFromTemplate, headerFilter],
    )

    const header = useMemo(() => {
        return (
            <Box justify='space-between' row fullWidth>
                <Text flex={1} color={Color.SmartColor}>
                    {checklistName}
                </Text>
                {selectedTemplate !== undefined &&
                    (headerFilter === AosChecklistFilter.EventChecklist ||
                        (headerFilter === AosChecklistFilter.TaskChecklist &&
                            currentUser.canEditTasks())) && (
                        <FormButton
                            label={translate('checklist-manager.templates.mark-reviewed')}
                            onClick={() =>
                                dispatch(
                                    reviewedChecklistTemplateAction(selectedTemplate?.id as number),
                                )
                            }
                            variant={FormButtonVariant.WhiteOutlined}
                        />
                    )}
            </Box>
        )
    }, [selectedTemplate, headerFilter, checklistName, currentUser])

    const onApplySeqChanges = () => {
        dispatch(applyItemSeqChangesAction(selectedTemplate!.id))
    }

    const onEditTaskOrDescription = (payload: AosChecklistItem) => {
        dispatch(
            editTaskOrDescriptionActions.showModalWithValueAction({
                payload: { item: payload, checklistTemplateId: selectedTemplate!.id },
                value: payload.content,
            }),
        )
    }

    const onEditUnitTask = (payload: AosUnitTaskItem) => {
        dispatch(showEditUnitTaskTemplateModalAction(payload))
    }

    const onEditMessageTemplate = (payload: AosChecklistItem) => {
        dispatch(
            editMessageTemplateActions.showModalWithValueAction({
                payload: {
                    checklistTemplateId: selectedTemplate!.id,
                    item: payload,
                },
                value: {
                    content: payload.content,
                    title: payload.title,
                },
            }),
        )
    }

    const selectChecklistPlaceholder = useMemo(() => {
        return (
            <PanelPlaceholder
                label={translate(
                    'checklist-manager.templates.select-checklist-template-placeholder',
                )}
                svg={SvgIcon.ChecklistPlaceholder}
            />
        )
    }, [])

    const noUnitTaskContentPlaceholder = useMemo(() => {
        return isEditable ? (
            <PanelPlaceholder
                onClick={() =>
                    dispatch(
                        showCreateUnitTaskItemModalAction(
                            (selectedTemplate as AosUnitTaskTemplate).severity,
                        ),
                    )
                }
                inline
                label={translate('checklist-manager.templates.create-task-placeholder')}
                svg={SvgIcon.CreateTask}
            />
        ) : (
            <PanelPlaceholder
                inline
                label={translate('checklist-manager.templates.empty-checklist-placeholder')}
                svg={SvgIcon.Checklist}
            />
        )
    }, [isEditable, selectedTemplate])

    const noChecklistContentPlaceholder = useMemo(() => {
        return isEditable ? (
            <PanelPlaceholder
                onClick={() =>
                    dispatch(
                        createTaskOrDescriptionActions.showModalWithPayloadAction(
                            selectedTemplate!.id,
                        ),
                    )
                }
                inline
                label={translate('checklist-manager.templates.create-task-placeholder')}
                svg={SvgIcon.CreateTask}
            />
        ) : (
            <PanelPlaceholder
                inline
                label={translate('checklist-manager.templates.empty-checklist-placeholder')}
                svg={SvgIcon.Checklist}
            />
        )
    }, [isEditable, selectedTemplate])

    const onAddChecklist = () =>
        dispatch(createTaskOrDescriptionActions.showModalWithPayloadAction(selectedTemplate!.id))

    const onAddUnitTask = () =>
        dispatch(
            showCreateUnitTaskItemModalAction((selectedTemplate as AosUnitTaskTemplate).severity),
        )

    const onChangeSeq = (v: ChangeSeqVector) => {
        dispatch(
            changeItemSeqAction({
                vector: v,
                checklistTemplateId: selectedTemplate!.id,
            }),
        )
    }

    const onCreateMessageTemplate = useCallback(() => {
        dispatch(
            editMessageTemplateActions.showModalWithValueAction({
                payload: {
                    checklistTemplateId: selectedTemplate!.id,
                },
                value: {
                    content: '',
                    title: '',
                },
            }),
        )
    }, [selectedTemplate])

    const noMessageTemplatesPlaceholder = useMemo(() => {
        return isEditable ? (
            <PanelPlaceholder
                onClick={onCreateMessageTemplate}
                inline
                label={translate('checklist-manager.templates.create-message-template-placeholder')}
                svg={SvgIcon.CreateMessageTemplatePlaceholder}
            />
        ) : (
            <PanelPlaceholder
                inline
                label={translate('checklist-manager.templates.empty-message-templates-placeholder')}
                svg={SvgIcon.MessagePlaceholder}
            />
        )
    }, [isEditable, onCreateMessageTemplate])

    const renderListsForEventChecklist = useCallback(
        (checkListTemplate: AosChecklistTemplate) => {
            return (
                <Box column fullHeight>
                    {checkListTemplate.tasksAndDescriptions && (
                        <ChecklistItemsSection
                            items={checkListTemplate.tasksAndDescriptions}
                            isEditable={isEditable}
                            title={translate('checklist-manager.templates.item-section-title')}
                            emptyPlaceholder={noChecklistContentPlaceholder}
                            onAdd={onAddChecklist}
                            onChangeSeq={onChangeSeq}
                            onApplySeqChanges={onApplySeqChanges}
                            onDelete={onDelete}
                            onEdit={onEditTaskOrDescription}
                        />
                    )}
                    {checkListTemplate.messageTemplates && (
                        <ChecklistItemsSection
                            items={checkListTemplate.messageTemplates}
                            isEditable={isEditable}
                            title={translate(
                                'checklist-manager.templates.message-template-section-title',
                            )}
                            emptyPlaceholder={noMessageTemplatesPlaceholder}
                            onAdd={onCreateMessageTemplate}
                            onChangeSeq={onChangeSeq}
                            onApplySeqChanges={onApplySeqChanges}
                            onDelete={onDelete}
                            onEdit={onEditMessageTemplate}
                        />
                    )}
                </Box>
            )
        },
        [isEditable, noChecklistContentPlaceholder, noMessageTemplatesPlaceholder],
    )

    const renderListsForUnitTasks = useCallback(
        (checkListTemplate: AosUnitTaskTemplate) => {
            return (
                <Box column fullHeight>
                    {checkListTemplate.items && (
                        <UnitTaskItemSection
                            items={checkListTemplate.items}
                            isEditable={isEditable}
                            title={translate('tasklist-manager.templates.item-section-title')}
                            emptyPlaceholder={noUnitTaskContentPlaceholder}
                            onAdd={onAddUnitTask}
                            onChangeSeq={onChangeSeq}
                            onApplySeqChanges={onApplySeqChanges}
                            onDelete={onDelete}
                            onEdit={onEditUnitTask}
                        />
                    )}
                </Box>
            )
        },
        [isEditable, noUnitTaskContentPlaceholder],
    )

    const renderListsForTaskChecklist = useCallback(
        (checkListTemplate: AosChecklistTemplate) => {
            return (
                <Box column fullHeight>
                    {checkListTemplate.items && (
                        <ChecklistItemsSection
                            items={checkListTemplate.items}
                            isEditable={isEditable}
                            title={translate('checklist-manager.templates.item-section-title')}
                            emptyPlaceholder={noChecklistContentPlaceholder}
                            onAdd={onAddChecklist}
                            onChangeSeq={onChangeSeq}
                            onApplySeqChanges={onApplySeqChanges}
                            onDelete={onDelete}
                            onEdit={onEditTaskOrDescription}
                        />
                    )}
                </Box>
            )
        },
        [isEditable, noChecklistContentPlaceholder],
    )

    const renderLists = useCallback(
        (checkListTemplate: AosChecklistTemplate | AosUnitTaskTemplate) => {
            switch (headerFilter) {
                case AosChecklistFilter.EventChecklist:
                    return renderListsForEventChecklist(checkListTemplate as AosChecklistTemplate)
                case AosChecklistFilter.UnitTasks:
                    return renderListsForUnitTasks(checkListTemplate as AosUnitTaskTemplate)
                case AosChecklistFilter.TaskChecklist:
                    return renderListsForTaskChecklist(checkListTemplate as AosChecklistTemplate)
            }
        },
        [headerFilter, isEditable, noChecklistContentPlaceholder, noUnitTaskContentPlaceholder],
    )
    const renderContent = (checkListTemplate: AosChecklistTemplate) => (
        <ResizableSplittedPanel
            initialRightSizes={columnSizes}
            onResize={value => dispatch(resizeColumnActionFactory('checklistManager')(value))}
            leftMinSizes={[20]}
            rightMinSizes={[20]}
        >
            {renderLists(checkListTemplate)}
            <ChecklistTemplateStatus checklistTemplate={checkListTemplate} />
        </ResizableSplittedPanel>
    )
    return (
        <PanelWithHeader bg={Color.ChecklistBackground} header={header}>
            {selectedTemplate
                ? renderContent(selectedTemplate as AosChecklistTemplate)
                : selectChecklistPlaceholder}
        </PanelWithHeader>
    )
}
