import { appConfig } from 'appConfig'

import Eka from './aoims/eka.svg'
import Eke from './aoims/eke.svg'
import Eku from './aoims/eku.svg'
import Epu from './aoims/epu.svg'
import Eru from './aoims/eru.svg'
import Tay from './aoims/tay.svg'
import Tll from './aoims/tll.svg'
import Enf from './aos/enf.svg'
import Hel from './aos/hel.svg'
import Hem from './aos/hem.svg'
import Hyv from './aos/hyv.svg'
import Ivl from './aos/ivl.svg'
import Joe from './aos/joe.svg'
import Jyv from './aos/jyv.svg'
import Kaj from './aos/kaj.svg'
import Kao from './aos/kao.svg'
import Kem from './aos/kem.svg'
import Kev from './aos/kev.svg'
import Kok from './aos/kok.svg'
import Ktt from './aos/ktt.svg'
import Kuo from './aos/kuo.svg'
import Lpp from './aos/lpp.svg'
import Mhq from './aos/mhq.svg'
import Mik from './aos/mik.svg'
import Oul from './aos/oul.svg'
import Por from './aos/por.svg'
import Rvn from './aos/rvn.svg'
import Sjy from './aos/sjy.svg'
import Svl from './aos/svl.svg'
import Tku from './aos/tku.svg'
import Tmp from './aos/tmp.svg'
import Uti from './aos/uti.svg'
import Vaa from './aos/vaa.svg'
import Vrk from './aos/vrk.svg'

const iconConfig: Record<string, any> = {
    AOIMS: { Eka, Eke, Eku, Epu, Eru, Tay, Tll },
    AOS: {
        Enf,
        Hel,
        Hem,
        Hyv,
        Ivl,
        Joe,
        Jyv,
        Kaj,
        Kao,
        Kem,
        Kev,
        Kok,
        Ktt,
        Kuo,
        Lpp,
        Mhq,
        Mik,
        Oul,
        Por,
        Rvn,
        Sjy,
        Svl,
        Tku,
        Tmp,
        Uti,
        Vaa,
        Vrk,
    },
}
export const AirportIconsSvg = iconConfig[appConfig.theme]
