import { Feature, Geometry } from 'geojson'
import { GeoJSON } from 'ol/format'
import { Layer } from 'ol/layer'

export type GeoJSONWithFeature = GeoJSON & {
    features?: Feature<Geometry>[] | null
}

export type LayerLoader = () => Promise<GeoJSON | null> | Promise<GeoJSONWithFeature>

export interface LayerFactory {
    createLoader(layerConfig: MapLayer): Promise<LayerLoader>
}

export interface MapLayer {
    id: string
    name: string
    category: string
    zIndex: number
    minZoom: number
    maxZoom: number
    scale: number

    isVector?: boolean
    customLoader?: CustomLoader
    customProperties?: (config: MapLayer) => Record<string, any>
    selectedMapElementType?: string
    originalName?: string
    serviceType?: 'WMS' | 'WMTS'
    geojson?: GeoJSONWithFeature
    floor?: number
}

export interface CustomLoader {
    id: string
    type: LoaderType
    variant: LoaderVariant
    customHandler?: string
}

export enum LoaderVariant {
    Basic = 'basic',
    Advanced = 'advanced',
}

export enum LoaderType {
    GeoJson = 'geojson',
}

export enum LayerProvider {
    Arcgis = 'arcgis',
    Vektor = 'vektor',
}

export type GeoLayerProvider = (
    layerId: string,
) => Promise<GeoJSON | null> | Promise<GeoJSONWithFeature>

export type LayerConfiguration = {
    provider: LayerProvider
    availableFloors: number[]
    airports: {
        [airportId: string | symbol]: MapLayer[]
    }
}

export interface MapProvider {
    loadLayer: GeoLayerProvider
    initialise: () => MapProvider
    mount(props: { bcContainer: string; bcOptions: any }): Promise<void>
    addMapLayer(layerId: string): Promise<Layer | null>
    removeMapLayer(layerId: string): void
    addMapLayerAsGeoJson(
        layerId: string,
        data: GeoJSON,
        minZoom: number,
        maxZoom: number,
        scale: number,
        zIndex: number,
    ): Promise<Layer | null>
    addMapLayerAsVector(layerId: string): Promise<Layer>
}
