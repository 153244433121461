import { mapEngine } from 'aos-map-engine/src'
import { GeoJSONWithFeature, MapLayer } from 'aos-map-engine/src/Types'
import {
    SelectedMapElementType,
    trainForSelectedElement,
} from 'aos-services/src/services/map/types/SelectedMapElement'
import GeoJSON from 'ol/format/GeoJSON'
import React, { forwardRef, PropsWithChildren } from 'react'

import { BimLayerName } from '../bim/BimLayerName'
import { LayerId } from '../LayerId'
import { SingleMapProps } from '../MapProps'
import { BimMap, BimMapRef } from '../openlayers/BimMap'
import { BimGeoLayer } from '../openlayers/layers/BimGeoLayer'
import { BimMapLayer } from '../openlayers/layers/BimMapLayer'
import { BimVectorLayer } from '../openlayers/layers/BimVectorLayer'
import { OSMLightLayer } from '../openlayers/layers/OSMLightLayer'
import { RingRailLayer } from '../openlayers/layers/RingRailLayer'
import { OpenlayersMap } from '../openlayers/OpenlayersMap'

export const TerminalMap = forwardRef<BimMapRef, PropsWithChildren<SingleMapProps>>(
    (props, ref) => {
        const {
            mapState: { selectedElement: selectedElement },
            onBoundsChanged,
            layersData,
            children,
            bimLayersState,
            userLayerVisibility,
        } = props
        const isLayerVisible = (bimLayerName: string) =>
            userLayerVisibility.list?.includes(bimLayerName)

        const loadBimGeoLayersWithFloor = (bimGeoLayers?: MapLayer[]) =>
            bimGeoLayers?.map(bimGeoLayer => {
                return mapEngine.availableFloors.map(floor => {
                    let geojson: Partial<GeoJSONWithFeature | GeoJSON>

                    if ((bimGeoLayer.geojson as unknown as GeoJSONWithFeature).features) {
                        const newFeatures = (
                            bimGeoLayer.geojson as unknown as GeoJSONWithFeature
                        ).features?.filter(feature => {
                            if (feature.properties?.visible_on_floors) {
                                return feature.properties.visible_on_floors.includes(
                                    floor.toString(),
                                )
                            } else if (feature.properties?.floor) {
                                return feature.properties.floor === floor
                            } else {
                                return true
                            }
                        })
                        geojson = {
                            ...bimGeoLayer.geojson,
                            features: newFeatures,
                        }
                    } else {
                        geojson = bimGeoLayer.geojson as Partial<GeoJSON | GeoJSONWithFeature>
                    }
                    return (
                        <BimGeoLayer
                            key={bimGeoLayer.name + floor}
                            layerId={bimGeoLayer.name + floor}
                            data={geojson as GeoJSON}
                            zIndex={bimGeoLayer.zIndex}
                            minZoom={bimGeoLayer.minZoom}
                            maxZoom={bimGeoLayer.maxZoom}
                            isVisible={isLayerVisible(bimGeoLayer.name)}
                            selectionTarget={bimGeoLayer.selectedMapElementType}
                            scale={bimGeoLayer.scale}
                            floorNumber={userLayerVisibility.floor}
                        />
                    )
                })
            })

        const loadBimMapLayers = () => {
            if (bimLayersState.list && bimLayersState.list.length > 0) {
                const nonLoadingLayers = [
                    BimLayerName.Tasks,
                    BimLayerName.Events,
                    BimLayerName.Trains,
                ]
                return bimLayersState.list
                    ?.filter(i => !i.geojson && !i.isVector)
                    .filter(i => !nonLoadingLayers.some(j => i.name.includes(j)))
                    .map(bimLayer => (
                        <BimMapLayer
                            layerId={bimLayer.name}
                            key={bimLayer.id}
                            mapLayerId={bimLayer.id}
                            zIndex={bimLayer.zIndex}
                            isVisible={isLayerVisible(bimLayer.name)}
                        />
                    ))
            } else {
                return null
            }
        }

        const loadBimGeoLayers = () => {
            if (bimGeoLayers && bimGeoLayers.length > 0) {
                const allCategoryBimGeoLayers = bimLayersState.list?.filter(i => i.geojson)
                return loadBimGeoLayersWithFloor(allCategoryBimGeoLayers)
            } else {
                return null
            }
        }

        const loadBimVectorLayers = () => {
            if (bimLayersState.list && bimLayersState.list.length > 0) {
                return bimLayersState.list
                    ?.filter(i => i.isVector)
                    .map(bimLayer => (
                        <BimVectorLayer
                            key={bimLayer.id}
                            mapLayerId={bimLayer.id}
                            zIndex={bimLayer.zIndex}
                            isVisible={isLayerVisible(bimLayer.name)}
                        />
                    ))
            } else {
                return null
            }
        }

        const loadMapLayers = () => {
            if (!bimLayersState.isLoading && !userLayerVisibility.isLoading) {
                return (
                    <>
                        {loadBimMapLayers()}
                        {loadBimGeoLayers()}
                        {loadBimVectorLayers()}
                    </>
                )
            }
            return null
        }

        const bimGeoLayers = bimLayersState.list?.filter(i => i.geojson)
        const selectedTrain = trainForSelectedElement(selectedElement)

        return (
            <OpenlayersMap minZoom={11} initialBoundsSet={onBoundsChanged}>
                <OSMLightLayer zIndex={1} />
                <RingRailLayer
                    layerId={LayerId.Trains}
                    zIndex={15}
                    isVisible={isLayerVisible(BimLayerName.Trains)}
                    trainLocations={layersData.ringRailTrainsInfo?.trains ?? []}
                    selectionTarget={SelectedMapElementType.Train}
                    selectedTrain={selectedTrain}
                />
                <div id='bcContainer' style={{ position: 'absolute' }} />
                <BimMap ref={ref}>{loadMapLayers()}</BimMap>
                {children}
            </OpenlayersMap>
        )
    },
)
