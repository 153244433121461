import { TimeValuePoint } from 'aos-services/src/services/airportStatus/base/types/TimePoint'
import {
    getLvpProbabilityTypeForWeatherProbabilityValue,
    getTypeForWindGusts,
    getWeatherProbabilityTypeForWeatherProbabilityValue,
    WeatherCategory,
    WeatherProbabilityType,
    WeatherWindGustsType,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { WeatherValueSeriesType } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'

const weatherCategoryToWeatherIconMap: Map<WeatherCategory, [Svg, Color]> = new Map<
    WeatherCategory,
    [Svg, Color]
>([
    [WeatherCategory.Normal, [SvgIcon.WeatherNormal, Color.Background]],
    [WeatherCategory.Rain, [SvgIcon.WeatherRain, Color.Primary]],
    [WeatherCategory.Snow, [SvgIcon.WeatherSnow, Color.SeaBlue]],
    [WeatherCategory.RainSnow, [SvgIcon.WeatherSnowRain, Color.Violet]],
])

const weatherSeriesTypeToWeatherColorMap: Map<WeatherValueSeriesType, Color> = new Map<
    WeatherValueSeriesType,
    Color
>([
    [WeatherValueSeriesType.Temperature, Color.White],
    [WeatherValueSeriesType.DewPoint, Color.PaleOrange],
    [WeatherValueSeriesType.Rain, Color.Primary],
    [WeatherValueSeriesType.Snow, Color.SeaBlue],
    [WeatherValueSeriesType.RainAndSnow, Color.Violet],

    [WeatherValueSeriesType.WindSpeed, Color.White],
    [WeatherValueSeriesType.CumulonimbusCloudsBase, Color.PaleOrange],
    [WeatherValueSeriesType.MaxWindGust, Color.Primary],

    [WeatherValueSeriesType.WindDirection, Color.White],
])

const weatherWindGustsTypeToWeatherColorMap: Map<WeatherWindGustsType, Color> = new Map<
    WeatherWindGustsType,
    Color
>([
    [WeatherWindGustsType.Small, Color.Primary],
    [WeatherWindGustsType.Normal, Color.SeaBlue],
    [WeatherWindGustsType.Big, Color.Violet],
    [WeatherWindGustsType.Extreme, Color.Magenta],
])

const weatherProbabilityTypeToWeatherColorMap: Map<WeatherProbabilityType, Color> = new Map<
    WeatherProbabilityType,
    Color
>([
    [WeatherProbabilityType.None, Color.WidgetBackground],
    [WeatherProbabilityType.Small, Color.SeaBlue],
    [WeatherProbabilityType.Normal, Color.Green],
    [WeatherProbabilityType.Big, Color.Yellow],
    [WeatherProbabilityType.Extreme, Color.Magenta],
])

export const getWeatherIconForWeatherCategory = (weatherCategory: WeatherCategory) =>
    weatherCategoryToWeatherIconMap.get(weatherCategory)

export const getWeatherColorForWeatherSeries = (weatherSeriesType: WeatherValueSeriesType) =>
    weatherSeriesTypeToWeatherColorMap.get(weatherSeriesType) || Color.White

export const getWeatherColorForWeatherWindGustsValue =
    (unit: WeatherUnit) => (weatherPoint: TimeValuePoint) => {
        const weatherWindGustsType = getTypeForWindGusts(unit, weatherPoint)
        return weatherWindGustsTypeToWeatherColorMap.get(weatherWindGustsType) || Color.Primary
    }

export const getWeatherColorForWeatherProbabilityValue = (weatherPoint: TimeValuePoint) => {
    const weatherProbabilityType = getWeatherProbabilityTypeForWeatherProbabilityValue(weatherPoint)
    return (
        weatherProbabilityTypeToWeatherColorMap.get(weatherProbabilityType) ||
        Color.WidgetBackground
    )
}

export const getLvpColorForWeatherProbabilityValue = (weatherPoint: TimeValuePoint) => {
    const weatherProbabilityType = getLvpProbabilityTypeForWeatherProbabilityValue(weatherPoint)
    return (
        weatherProbabilityTypeToWeatherColorMap.get(weatherProbabilityType) ||
        Color.WidgetBackground
    )
}
