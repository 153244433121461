import { modalContentFromKeyPrefix } from 'aos-helpers/src/helpers/ModalContentBuilder'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { isApocSelector } from 'aos-services/src/core/auth/state'
import {
    allAosChecklistFilters,
    AosChecklistFilter,
    translateAosChecklistFilter,
} from 'aos-services/src/services/checklists/types/AosChecklist'
import { MainPanelWithHeader } from 'aos-ui/src/components/container/MainPanelWithHeader'
import { PanelWithHeaderVariant } from 'aos-ui/src/components/container/PanelWithHeader'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { HeaderFilter } from 'aos-ui/src/components/header/HeaderFilter'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { showActionModalAction } from '../../core/actionModal/actions'
import {
    exportAllChecklistTemplatesAction,
    setChecklistFilteringAction,
} from '../../core/checklistManager/actions'
import { showCreateChecklistTemplateModalAction } from '../../core/checklistManager/event/actions'
import {
    exportAllTaskChecklistTemplatesAction,
    showCreateTaskChecklistTemplateModalAction,
} from '../../core/checklistManager/task/actions'
import {
    exportAllUnitTaskTemplatesAction,
    showCreateUnitTaskItemModalAction,
} from '../../core/checklistManager/unit/actions'
import { State } from '../../core/state'
import { ChecklistTemplateList } from './event/ChecklistTemplateList'
import { TaskChecklistTemplateList } from './task/TaskChecklistTemplateList'
import { UnitTaskTemplateList } from './unitTask/UnitTaskTemplateList'

export const ChecklistManagerList = () => {
    const dispatch = useDispatch()
    const isHel = useSelector((state: State) => state.auth.currentUser.isMainAirport())
    const isApoc = useSelector(isApocSelector)
    const currentUser = useSelector((state: State) => state.auth.currentUser)
    const { exportingChecklistTemplates, headerFilter } = useSelector(
        (state: State) => state.checklistManager,
    )
    const exportAllChecklistsModalAction = () => {
        dispatch(
            showActionModalAction({
                ...modalContentFromKeyPrefix('checklist-manager.export-checklist'),
                svg: SvgIcon.ExportChecklistTemplates,
                onClickAction: () => dispatch(exportAllChecklistTemplatesAction()),
            }),
        )
    }

    const exportAllUnitTaskTemplatesModalAction = () => {
        dispatch(
            showActionModalAction({
                ...modalContentFromKeyPrefix('checklist-manager.export-tasklist'),
                svg: SvgIcon.ExportChecklistTemplates,
                onClickAction: () => dispatch(exportAllUnitTaskTemplatesAction()),
            }),
        )
    }

    const exportAllTaskChecklistTemplatesModalAction = () => {
        dispatch(
            showActionModalAction({
                ...modalContentFromKeyPrefix('checklist-manager.export-task-checklist'),
                svg: SvgIcon.ExportChecklistTemplates,
                onClickAction: () => dispatch(exportAllTaskChecklistTemplatesAction()),
            }),
        )
    }

    const showCreateChecklistTemplateModal = () => {
        dispatch(showCreateChecklistTemplateModalAction())
    }

    const showCreateUnitTaskItemModal = () => {
        dispatch(showCreateUnitTaskItemModalAction(undefined))
    }

    const showCreateTaskChecklistModal = () => {
        dispatch(showCreateTaskChecklistTemplateModalAction())
    }

    const moreMenu = useMemo(() => {
        switch (headerFilter) {
            case AosChecklistFilter.EventChecklist:
                return (
                    <More iconVariant={IconVariant.Smart}>
                        <MoreItem onClick={showCreateChecklistTemplateModal}>
                            {translate('checklist-manager.add-group')}
                        </MoreItem>
                        <MoreItem
                            onClick={exportAllChecklistsModalAction}
                            disabled={exportingChecklistTemplates}
                        >
                            {translate('checklist-manager.export-all-checklists')}
                        </MoreItem>
                    </More>
                )
            case AosChecklistFilter.UnitTasks:
                return (
                    <More>
                        <MoreItem onClick={showCreateUnitTaskItemModal}>
                            {translate('checklist-manager.add-tasklist')}
                        </MoreItem>
                        <MoreItem
                            onClick={exportAllUnitTaskTemplatesModalAction}
                            disabled={exportingChecklistTemplates}
                        >
                            {translate('checklist-manager.export-all-tasklist')}
                        </MoreItem>
                    </More>
                )
            case AosChecklistFilter.TaskChecklist:
                return (
                    <More>
                        {currentUser.canEditTasks() && (
                            <MoreItem onClick={showCreateTaskChecklistModal}>
                                {translate('checklist-manager.add-task-checklist')}
                            </MoreItem>
                        )}
                        <MoreItem
                            onClick={exportAllTaskChecklistTemplatesModalAction}
                            disabled={exportingChecklistTemplates}
                        >
                            {translate('checklist-manager.export-all-task-checklist')}
                        </MoreItem>
                    </More>
                )
            default:
                return null
        }
    }, [headerFilter, currentUser])

    const allowedHeaders = useMemo(() => {
        if (isApoc) {
            return currentUser.canViewTasks()
                ? allAosChecklistFilters
                : [AosChecklistFilter.EventChecklist, AosChecklistFilter.UnitTasks]
        }
        return currentUser.canViewTasks() ? [AosChecklistFilter.TaskChecklist] : []
    }, [isHel, isApoc, currentUser])

    useEffect(() => {
        if (!allowedHeaders.includes(headerFilter)) {
            dispatch(setChecklistFilteringAction(allowedHeaders[0]))
        }
    }, [allowedHeaders])

    const header = (
        <HeaderContainer
            title={
                <HeaderFilter
                    items={allowedHeaders}
                    value={headerFilter}
                    onChange={item => dispatch(setChecklistFilteringAction(item))}
                    valueFormatter={translateAosChecklistFilter}
                />
            }
            rightButton={moreMenu}
        />
    )

    const content = useMemo(() => {
        switch (headerFilter) {
            case AosChecklistFilter.EventChecklist:
                return <ChecklistTemplateList />
            case AosChecklistFilter.UnitTasks:
                return <UnitTaskTemplateList />
            case AosChecklistFilter.TaskChecklist:
                return <TaskChecklistTemplateList />
        }
    }, [headerFilter])

    return (
        <MainPanelWithHeader variant={PanelWithHeaderVariant.Large} header={header}>
            {content}
        </MainPanelWithHeader>
    )
}
