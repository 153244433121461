import { withDragDropContext } from 'aos-components/src/helpers/DnD'
import {
    AosChecklistItemType,
    TaskOrDescription,
} from 'aos-services/src/services/checklists/types/AosCheckListItemType'
import { Box } from 'aos-ui/src/components/base/Box'
import { SplittedPanel } from 'aos-ui/src/components/container/SplittedPanel'
import { Color } from 'aos-ui-common/src/styles/Color'
import { kebabCase } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import { loadChecklistTemplatesAction } from '../../core/checklistManager/event/actions'
import {
    currentCreateTaskOrDescriptionItemType,
    currentEditTaskOrDescriptionItemType,
    isCreateMessageTemplateDialog,
} from '../../core/checklistManager/selectors'
import { loadTaskChecklistTemplatesAction } from '../../core/checklistManager/task/actions'
import { loadUnitTaskTemplatesAction } from '../../core/checklistManager/unit/actions'
import { AddChecklistTemplateModal } from './checklist/checklistModals/checklistTemplate/AddChecklistTemplateModal'
import { EditChecklistTemplateModal } from './checklist/checklistModals/checklistTemplate/EditChecklistTemplateModal'
import { CreateTaskOrDescriptionModal } from './checklist/checklistModals/CreateTaskOrDescriptionModal'
import { EditMessageTemplateModal } from './checklist/checklistModals/EditMessageTemplateModal'
import { EditTaskOrDescriptionModal } from './checklist/checklistModals/EditTaskOrDescriptionModal'
import { ChecklistManagerList } from './ChecklistManagerList'
import { ChecklistTemplateContent } from './ChecklistTemplateContent'
import { AddTaskChecklistModal } from './task/taskChecklistModals/AddTaskChecklistModal'
import { EditTaskChecklistModal } from './task/taskChecklistModals/EditTaskChecklistModal'
import { AddUnitTaskItemModal } from './unitTask/unitTaskModals/AddUnitTaskItemModal'
import { EditUnitTaskItemModal } from './unitTask/unitTaskModals/EditUnitTaskItemModal'

const ChecklistComponent = () => {
    const createMessageTemplateDialog = useSelector(isCreateMessageTemplateDialog)
    const currentEditTaskOrDescriptionItem = useSelector(currentEditTaskOrDescriptionItemType)
    const currentCreateTaskOrDescriptionItem = useSelector(currentCreateTaskOrDescriptionItemType)

    const buildKeyPrefix = (
        action: 'add' | 'edit',
        itemType?: AosChecklistItemType | TaskOrDescription,
    ) => `checklist-manager.${action}-${kebabCase(itemType)}`

    const currentEditMessageKeyPrefix = buildKeyPrefix(
        createMessageTemplateDialog ? 'add' : 'edit',
        AosChecklistItemType.MessageTemplate,
    )

    return (
        <SyncWrapper
            onEnter={[
                loadChecklistTemplatesAction(),
                loadUnitTaskTemplatesAction(),
                loadTaskChecklistTemplatesAction(),
            ]}
        >
            <Box bg={Color.ChecklistBackground} fullHeight row>
                <SplittedPanel separatedHeader>
                    <ChecklistManagerList />
                    <ChecklistTemplateContent />
                </SplittedPanel>
                <AddUnitTaskItemModal />
                <EditUnitTaskItemModal />
                <AddChecklistTemplateModal />
                <EditChecklistTemplateModal />
                <EditTaskOrDescriptionModal
                    keyPrefix={buildKeyPrefix('edit', currentEditTaskOrDescriptionItem)}
                />
                <CreateTaskOrDescriptionModal
                    keyPrefix={buildKeyPrefix('add', currentCreateTaskOrDescriptionItem)}
                />
                <EditMessageTemplateModal keyPrefix={currentEditMessageKeyPrefix} />
                <AddTaskChecklistModal />
                <EditTaskChecklistModal />
            </Box>
        </SyncWrapper>
    )
}

export const ChecklistManager = withDragDropContext(ChecklistComponent)
