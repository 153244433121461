import { Color } from 'aos-ui-common/src/styles/Color'
import { flatMap } from 'lodash'

export enum FlightNatureCode {
    CharterDomestic = 'CD',
    CharterSchengen = 'CS',
    CharterNonSchengen = 'CE',
    CharterInternational = 'CI',
    ScheduledDomestic = 'SD',
    ScheduledSchengen = 'SS',
    ScheduledNonSchengen = 'SE',
    ScheduledInternational = 'SI',
    ExtraDomestic = 'ED',
    ExtraSchengen = 'ES',
    ExtraNonSchengen = 'EE',
    ExtraInternational = 'EI',
    FreightDomestic = 'FD',
    FreightSchengen = 'FS',
    FreightNonSchengen = 'FE',
    FreightInternational = 'FI',
    PositionDomestic = 'PD',
    PositionSchengen = 'PS',
    PositionNonSchengen = 'PE',
    PositionInternational = 'PI',
    SmallPropellerDomestic = 'ND',
    SmallPropellerSchengen = 'NS',
    SmallPropellerNonSchengen = 'NE',
    SmallPropellerInternational = 'NI',
    NonPublicDomestic = 'YD',
    NonPublicSchengen = 'YS',
    NonPublicNonSchengen = 'YE',
    NonPublicInternational = 'YI',
    Unknown = 'UN',
}

export enum NatureOfFlightGroup {
    Domestic = 'Domestic',
    Schengen = 'Schengen',
    NonSchengen = 'Non-Schengen',
    International = 'International',
    Other = 'Other',
}

export const natureOfFlightGroups: Record<NatureOfFlightGroup, FlightNatureCode[]> = {
    [NatureOfFlightGroup.Domestic]: [
        FlightNatureCode.CharterDomestic,
        FlightNatureCode.ScheduledDomestic,
        FlightNatureCode.ExtraDomestic,
        FlightNatureCode.FreightDomestic,
        FlightNatureCode.PositionDomestic,
        FlightNatureCode.SmallPropellerDomestic,
        FlightNatureCode.NonPublicDomestic,
    ],
    [NatureOfFlightGroup.Schengen]: [
        FlightNatureCode.CharterSchengen,
        FlightNatureCode.ScheduledSchengen,
        FlightNatureCode.ExtraSchengen,
        FlightNatureCode.FreightSchengen,
        FlightNatureCode.PositionSchengen,
        FlightNatureCode.SmallPropellerSchengen,
        FlightNatureCode.NonPublicSchengen,
    ],
    [NatureOfFlightGroup.NonSchengen]: [
        FlightNatureCode.CharterNonSchengen,
        FlightNatureCode.ScheduledNonSchengen,
        FlightNatureCode.ExtraNonSchengen,
        FlightNatureCode.FreightNonSchengen,
        FlightNatureCode.PositionNonSchengen,
        FlightNatureCode.SmallPropellerNonSchengen,
        FlightNatureCode.NonPublicNonSchengen,
    ],
    [NatureOfFlightGroup.International]: [
        FlightNatureCode.CharterInternational,
        FlightNatureCode.ScheduledInternational,
        FlightNatureCode.ExtraInternational,
        FlightNatureCode.FreightInternational,
        FlightNatureCode.PositionInternational,
        FlightNatureCode.SmallPropellerInternational,
        FlightNatureCode.NonPublicInternational,
    ],
    [NatureOfFlightGroup.Other]: [],
}

export enum FlightSecurityLevel {
    Normal = 1,
    Border = 2,
    BorderSecurity = 3,
}

type NatureOfFlightSecurityLevelMapping = [FlightSecurityLevel, FlightNatureCode[]]

const natureOfFlightSecurityLevels: NatureOfFlightSecurityLevelMapping[] = [
    [
        FlightSecurityLevel.Normal,
        [
            FlightNatureCode.CharterDomestic,
            FlightNatureCode.CharterSchengen,
            FlightNatureCode.ScheduledDomestic,
            FlightNatureCode.ScheduledSchengen,
            FlightNatureCode.ExtraDomestic,
            FlightNatureCode.ExtraSchengen,
            FlightNatureCode.FreightDomestic,
            FlightNatureCode.FreightSchengen,
            FlightNatureCode.PositionDomestic,
            FlightNatureCode.PositionSchengen,
            FlightNatureCode.SmallPropellerDomestic,
            FlightNatureCode.SmallPropellerSchengen,
            FlightNatureCode.NonPublicDomestic,
            FlightNatureCode.NonPublicSchengen,
        ],
    ],
    [
        FlightSecurityLevel.Border,
        [
            FlightNatureCode.CharterNonSchengen,
            FlightNatureCode.ScheduledNonSchengen,
            FlightNatureCode.ExtraNonSchengen,
            FlightNatureCode.FreightNonSchengen,
            FlightNatureCode.PositionNonSchengen,
            FlightNatureCode.SmallPropellerNonSchengen,
            FlightNatureCode.NonPublicNonSchengen,
        ],
    ],
    [
        FlightSecurityLevel.BorderSecurity,
        [
            FlightNatureCode.CharterInternational,
            FlightNatureCode.ScheduledInternational,
            FlightNatureCode.ExtraInternational,
            FlightNatureCode.FreightInternational,
            FlightNatureCode.PositionInternational,
            FlightNatureCode.SmallPropellerInternational,
            FlightNatureCode.NonPublicInternational,
        ],
    ],
]

interface FlightSecurityLevelColorMapping {
    [key: number]: Color
}

const flightSecurityColors: FlightSecurityLevelColorMapping = {
    [FlightSecurityLevel.Normal]: Color.ChartBase,
    [FlightSecurityLevel.Border]: Color.Primary,
    [FlightSecurityLevel.BorderSecurity]: Color.Red,
}

const natureOfFlightColorsMap = new Map<FlightNatureCode, Color>(
    flatMap(natureOfFlightSecurityLevels, (level: NatureOfFlightSecurityLevelMapping) =>
        level[1].map(
            (naflt: FlightNatureCode) =>
                [naflt, flightSecurityColors[level[0]]] as [FlightNatureCode, Color],
        ),
    ),
)

export const getColorForFlightNature = (naflt?: FlightNatureCode): Color =>
    naflt ? natureOfFlightColorsMap.get(naflt)! : Color.ChartBase
