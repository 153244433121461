import { customLayerHandlers } from 'aos-services/src/services/layerData/CustomLayerHandlers'
import { Feature } from 'geojson'

import { createProviderFactory } from './ProviderFactory'
import {
    GeoJSONWithFeature,
    LayerFactory,
    LayerLoader,
    LayerProvider,
    LoaderVariant,
    MapLayer,
    MapProvider,
} from './Types'

export class GeoJsonLayerFactory implements LayerFactory {
    private provider: MapProvider | null = null
    private providerResolvers: Array<(provider: MapProvider) => void> = []
    constructor(provider: LayerProvider) {
        void this.onInit(provider)
    }

    getMapProvider(): Promise<MapProvider> {
        return new Promise<MapProvider>(resolve => {
            if (this.provider) {
                resolve(this.provider)
            } else {
                this.providerResolvers.push(resolve)
            }
        })
    }

    async createLoader(layerConfig: MapLayer): Promise<LayerLoader> {
        switch (layerConfig.customLoader?.variant) {
            case LoaderVariant.Basic:
                return this.createBasicLoader(layerConfig.customLoader.id)
            case LoaderVariant.Advanced:
                layerConfig.customProperties =
                    customLayerHandlers[layerConfig.customLoader.customHandler!]
                return await this.createAdvancedLoader(layerConfig)
            default:
                throw new Error(`Unknown loader type: ${layerConfig.customLoader?.variant}`)
        }
    }

    private createBasicLoader(layerId: string): LayerLoader {
        return () => this.provider?.loadLayer(layerId)!
    }

    private async createAdvancedLoader(config: MapLayer): Promise<LayerLoader> {
        if (!this.provider) {
            throw new Error('Provider is not initialized')
        }
        const layerData: GeoJSONWithFeature | null = await this.provider.loadLayer(
            config.customLoader!.id,
        )
        if (!layerData) {
            throw new Error('Layer data is not available')
        }
        const modifiedFeatures = config.customProperties!({ ...config, geojson: layerData })
        const result = {
            ...layerData,
            features: modifiedFeatures as Feature[],
        } as GeoJSONWithFeature

        return async () => result
    }

    private onInit = async (provider: LayerProvider) => {
        this.provider = await createProviderFactory(provider)
        this.providerResolvers.forEach(resolve => resolve(this.provider!))
        this.providerResolvers = []
    }
}
