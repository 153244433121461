import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'
import { identity, some, sortBy } from 'lodash'

export enum AosSeverity {
    Maintenance = 'MAINTENANCE',
    Info = 'INFO',
    Notice = 'NOTICE',
    Incident = 'INCIDENT',
    Emergency = 'EMERGENCY',
}

export const eventSeverities = [
    AosSeverity.Maintenance,
    AosSeverity.Info,
    AosSeverity.Notice,
    AosSeverity.Incident,
    AosSeverity.Emergency,
]

export const atcEventSeverities = [AosSeverity.Info, AosSeverity.Notice, AosSeverity.Emergency]

export const emergencyTaskSeverities = [AosSeverity.Notice, AosSeverity.Emergency]

export const taskSeverities = [AosSeverity.Info, AosSeverity.Maintenance, AosSeverity.Emergency]

export const recurringTaskSeverities = [AosSeverity.Info, AosSeverity.Maintenance]

export const allSeverities = EnumValues.getValues<AosSeverity>(AosSeverity)

const aosSeverityOrder: Map<AosSeverity, number> = new Map(
    allSeverities.map((t, index) => [t, index] as [AosSeverity, number]),
)

export const severitiesOrderGetter =
    <T>(getter: f.Func1<T, AosSeverity>) =>
    (t: T) =>
        (aosSeverityOrder.get(getter(t)) || 0) * -1

export function sortBySeverity<T>(items: T[], getter: f.Func1<T, AosSeverity>): T[] {
    return sortBy(items, severitiesOrderGetter(getter))
}

export function sortSeverities(items: AosSeverity[]): AosSeverity[] {
    return sortBySeverity(items, identity)
}

export function isSeveritySchedulable(s?: AosSeverity): boolean {
    const schedulableSeverities = [AosSeverity.Maintenance, AosSeverity.Notice, AosSeverity.Info]
    return some(schedulableSeverities, t => t === s)
}

export const getBackgroundColorFromSeverity = (severity: AosSeverity | undefined) => {
    switch (severity) {
        case AosSeverity.Notice:
            return Color.Yellow
        case AosSeverity.Info:
            return Color.Green
        default:
            return Color.Red
    }
}

export const severityColorsMapping: Record<AosSeverity, Color> = {
    [AosSeverity.Maintenance]: Color.SideMenuInactive,
    [AosSeverity.Info]: Color.Green,
    [AosSeverity.Emergency]: Color.Red,
    [AosSeverity.Incident]: Color.Orange,
    [AosSeverity.Notice]: Color.Yellow,
}

export const taskSeverityColorsMapping: Record<AosSeverity, Color> = {
    [AosSeverity.Info]: Color.SideMenuInactive,
    [AosSeverity.Maintenance]: Color.TaskBlue,
    [AosSeverity.Emergency]: Color.Red,
    [AosSeverity.Incident]: Color.Orange,
    [AosSeverity.Notice]: Color.Yellow,
}

export const isSeverityHigh = (severity: AosSeverity): boolean =>
    severity === AosSeverity.Emergency || severity === AosSeverity.Incident

export const atcEventSeverityTitle = (e: AosSeverity) =>
    translateEnum<AosSeverity>(AosSeverity, 'atc-event-severity')(e)
export const eventSeverityTitle = (e: AosSeverity) =>
    translateEnum<AosSeverity>(AosSeverity, 'event-severity-new')(e)
export const taskSeverityTitle = (e: AosSeverity) =>
    translateEnum<AosSeverity>(AosSeverity, 'task-severity')(e)
