import { AosSeverity } from '../common/types/AosSeverity'
import { AosAirport, MainAirport } from '../flightInformation/types/AosAirport'
import { AosDashboardEvent } from './types/AosDashboardEvent'
import { AosEventProcessType } from './types/AosEventProcessType'
import { AosEventVisibility } from './types/AosEventVisibility'
import { AosTimelineEvent } from './types/AosTimelineEvent'

type AosEvent = AosTimelineEvent | AosDashboardEvent
export const filterEventUserVisibility = (
    event: AosEvent,
    siteLocation: AosAirport,
    isApoc: Boolean,
) => {
    const isEmergencyOrDisruption =
        event.severity === AosSeverity.Emergency || event.severity === AosSeverity.Incident
    const isNetworkProcess = event.process === AosEventProcessType.Network

    if (siteLocation === MainAirport) {
        return isPublicEvent(event) || event.hasPrivateChannel || isApoc
    } else {
        if (isPublicEvent(event) || isApoc) {
            return isEmergencyOrDisruption || isNetworkProcess
        } else {
            return event.hasPrivateChannel
        }
    }
}
function isPublicEvent(event: AosEvent): Boolean {
    return event.visibility === AosEventVisibility.Public
}
