import { queryFeatures } from '@esri/arcgis-rest-feature-layer'
import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { Map as OlMap } from 'ol'
import { isEmpty } from 'ol/extent'
import Feature from 'ol/Feature'
import { EsriJSON, GeoJSON } from 'ol/format'
import { Layer } from 'ol/layer'
import VectorLayer from 'ol/layer/Vector'
import { bbox as bboxStrategy } from 'ol/loadingstrategy'
import { Projection } from 'ol/proj'
import { register } from 'ol/proj/proj4'
import VectorSource from 'ol/source/Vector'
import proj4 from 'proj4'

import { MapProvider } from '../Types'

/**
 * proj4 is used to define the projection of the map. The EPSG:3301 projection is used in Estonia, but we use EPSG:3857 for the map.
 */
proj4.defs(
    'EPSG:3301',
    '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +units=m +no_defs',
)
register(proj4)

export class ArcgisProvider implements MapProvider {
    private readonly ORGANISATION_ID = 'mHd4eVxS0ivkQRap'
    private readonly featureServiceUrl = `https://services8.arcgis.com/${this.ORGANISATION_ID}/ArcGIS/rest/services/`
    private map: OlMap | null = null
    private layers: Map<string, Layer> = new Map()

    public async loadLayer(layerId: string) {
        try {
            return (await queryFeatures({
                url: `${this.featureServiceUrl}${layerId}/FeatureServer/0`,
                returnGeometry: true,
                f: 'geojson',
            })) as GeoJSON
        } catch (e) {
            logger.handleError(e as Error)
            return null
        }
    }

    public initialise(): MapProvider {
        // do nothing
        return this
    }

    public async mount(options: { bcContainer: string; bcOptions: { openLayersMap: OlMap } }) {
        this.map = options.bcOptions.openLayersMap
    }

    public async addMapLayer(layerId: string) {
        if (!this.map) {
            logger.handleError(new Error('Map instance is not initialized.'))
            return null
        }

        try {
            const vectorLayer = await this.addMapLayerAsVector(layerId)
            this.map.addLayer(vectorLayer)
            this.layers.set(layerId, vectorLayer)
            return vectorLayer as Layer
        } catch (e) {
            logger.handleError(e as Error)
            return null
        }
    }

    public removeMapLayer(layerId: string) {
        if (!this.map) {
            logger.handleError(new Error('Map instance is not initialized.'))
            return
        }

        const layer = this.layers.get(layerId)
        if (layer) {
            this.map.removeLayer(layer)
            this.layers.delete(layerId)
        } else {
            logger.log(`Layer with ID "${layerId}" not found.`)
        }
    }

    public async addMapLayerAsGeoJson(
        layerId: string,
        geojsonData: any = null,
        minZoom: number = 0,
        maxZoom: number = Infinity,
        opacity: number = 1,
        zIndex: number = 1,
    ) {
        if (!this.map) {
            logger.handleError(new Error('Map instance is not initialized.'))
            return null
        }

        try {
            let features
            if (geojsonData && !isEmpty(geojsonData)) {
                features = new GeoJSON().readFeatures(geojsonData, {
                    dataProjection: 'EPSG:3301',
                    featureProjection: this.map.getView().getProjection(),
                })
            } else {
                const result = await this.loadLayer(layerId)
                if (!result) {
                    throw new Error(`Failed to load layer with ID "${layerId}".`)
                }
                features = new GeoJSON().readFeatures(result, {
                    dataProjection: 'EPSG:3857',
                    featureProjection: this.map.getView().getProjection(),
                })
            }

            const vectorSource = new VectorSource({
                features: features,
            })

            const vectorLayer = new VectorLayer({
                source: vectorSource as VectorSource<Feature>,
                opacity: opacity,
            })

            vectorLayer.setZIndex(zIndex)
            vectorLayer.set('minZoom', minZoom)
            vectorLayer.set('maxZoom', maxZoom)

            this.map.addLayer(vectorLayer)
            this.layers.set(layerId, vectorLayer)
            return vectorLayer as Layer
        } catch (e) {
            logger.handleError(e as Error)
            return null
        }
    }

    public async addMapLayerAsVector(layerId: string) {
        const vectorSource = new VectorSource({
            format: new EsriJSON(),
            loader: (extent, resolution, projection) => {
                this.loadFeatures(layerId, extent, resolution, projection, vectorSource)
            },
            strategy: bboxStrategy,
        })

        return new VectorLayer({
            source: vectorSource,
        })
    }

    private async loadFeatures(
        layerId: string,
        _extent: number[],
        _res: number,
        projection: Projection,
        vectorSource: VectorSource,
    ) {
        try {
            const result = await queryFeatures({
                url: `${this.featureServiceUrl}${layerId}/FeatureServer/0`,
            })

            const features = new EsriJSON().readFeatures(result, {
                featureProjection: projection,
            })

            vectorSource.addFeatures(features as Feature[])
        } catch (e) {
            logger.handleError(e as Error)
        }
    }
}
