import { DateTime } from 'aos-helpers/src/helpers/Time'
import { CommonAuthStateAware, currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { currentTimeSelector } from 'aos-services/src/core/common/selectors'
import { TimelineState } from 'aos-services/src/core/timeline/state'
import {
    AosSeverity,
    severityColorsMapping,
} from 'aos-services/src/services/common/types/AosSeverity'
import { filterEventUserVisibility } from 'aos-services/src/services/events/aosEventFilters'
import { eventProcessTitle } from 'aos-services/src/services/events/aosEventFormatter'
import { AosCommonEventProps } from 'aos-services/src/services/events/types/AosCommonEventProps'
import {
    AosEventProcessType,
    helsinkiAirportGroups,
    networkAirportGroups,
} from 'aos-services/src/services/events/types/AosEventProcessType'
import { AosTimelineEvent } from 'aos-services/src/services/events/types/AosTimelineEvent'
import { MainAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { BaseCalendarTimelineGroup } from 'aos-ui/src/components/timeline/types/BaseCalendarTimelineGroup'
import { BaseCalendarTimelineItem } from 'aos-ui/src/components/timeline/types/BaseCalendarTimelineItem'
import { positionValues } from 'react-custom-scrollbars'
import { createSelector } from 'reselect'

import { EventTimelineFilter, EventTimelineStateAware } from './state'

export interface EventTimelineItem extends BaseCalendarTimelineItem, AosCommonEventProps {}

export interface GroupTimelineItem extends BaseCalendarTimelineGroup {
    process: AosEventProcessType
}

export interface EventTimelineSelectorState {
    groups: GroupTimelineItem[]
    events: EventTimelineItem[]
    currentTime: DateTime
    timelineState: TimelineState
    filter: EventTimelineFilter
    savedPosition?: positionValues
}

export const filterEvent = (event: AosTimelineEvent, severities: AosSeverity[]) => {
    if (severities.length === 0) {
        return true
    } else {
        return severities.includes(event.severity)
    }
}

const groupsAndEventsSelector = createSelector(
    (state: EventTimelineStateAware) => state.eventTimeline.events,
    (state: EventTimelineStateAware) => state.eventTimeline.collapsedGroups,
    (state: EventTimelineStateAware) => state.eventTimeline.filter,
    currentUserSiteLocation,
    (state: CommonAuthStateAware) => state.auth.currentUser.isApoc(),
    (state: CommonAuthStateAware) => state.auth.currentUser.userHasAnyUnitRole(),

    (timelineEvents, collapsedGroups, filter, siteLocation, isApoc) => {
        const orderedGroups =
            siteLocation !== MainAirport ? networkAirportGroups : helsinkiAirportGroups
        const groups: GroupTimelineItem[] = orderedGroups.map(process => {
            const isCollapsed = collapsedGroups.includes(process)
            return {
                id: process,
                process,
                timelineLabel: eventProcessTitle(process),
                isCollapsed,
                lineHeight: isCollapsed ? 10 : 50,
                minHeight: isCollapsed ? 40 : 150,
            }
        })

        const events: EventTimelineItem[] = timelineEvents
            .filter(e => filterEvent(e, filter.selectedSeverities))
            .filter(e => filterEventUserVisibility(e, siteLocation, isApoc))
            .map(e => ({
                id: e.id,
                group: e.process,
                outlined: e.hasPrivateChannel,
                startTime: e.startTime,
                endTime: e.endTime,
                isCollapsed: collapsedGroups.includes(e.process),
                title: e.title,
                hasPrivateChannel: e.hasPrivateChannel,
                process: e.process,
                visibility: e.visibility,
                severity: e.severity,
                color: severityColorsMapping[e.severity],
                category: e.category,
            }))

        return {
            groups,
            events,
        }
    },
)
export const eventTimelineSelector = createSelector(
    (state: EventTimelineStateAware) => state.eventTimeline.timeline,
    (state: EventTimelineStateAware) => state.eventTimeline.filter,
    currentTimeSelector,
    groupsAndEventsSelector,
    (timelineState, filter, currentTime, groupsAndEvents) => ({
        timelineState,
        currentTime,
        filter,
        groups: groupsAndEvents.groups,
        events: groupsAndEvents.events,
    }),
)
