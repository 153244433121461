import { AtcEventForm } from 'aos-frontend/src/app/views/eventModals/partial/AtcEventForm'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosSeverity,
    atcEventSeverities,
    atcEventSeverityTitle,
    eventSeverities,
    isSeveritySchedulable,
} from 'aos-services/src/services/common/types/AosSeverity'
import {
    eventProcessTitle,
    eventScenarioTitle,
    eventScenarioTitleTemplate,
} from 'aos-services/src/services/events/aosEventFormatter'
import {
    AosAtcEventProcessType,
    AosEventProcessType,
} from 'aos-services/src/services/events/types/AosEventProcessType'
import {
    AosEventScenario,
    eventScenarioIcon,
    scenarioProcessMapping,
} from 'aos-services/src/services/events/types/AosEventScenario'
import { AosEventVisibility } from 'aos-services/src/services/events/types/AosEventVisibility'
import { aosUserGroupName } from 'aos-services/src/services/users/aosUserFormatter'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AttachmentContainer } from 'aos-ui/src/components/attachment/items/AttachmentContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { FormButtonProps, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { Item, ToggleButton } from 'aos-ui/src/components/buttons/ToggleButton'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { LabeledSeverity } from 'aos-ui/src/components/form/labeled/LabeledSeverity'
import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import { LabeledTimeRange } from 'aos-ui/src/components/form/labeled/LabeledTimeRange'
import { Location } from 'aos-ui/src/components/form/location/Location'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { DefaultRadioRenderer } from 'aos-ui/src/components/form/radio/DefaultRadioRenderer'
import { RadioGroup } from 'aos-ui/src/components/form/radio/RadioGroup'
import { TwoColumnFormContainer } from 'aos-ui/src/components/form/ui/TwoColumnFormContainer'
import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'
import { Icon, IconVariant, svgIconForName } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { loadCommonGroupsAction } from '../../../core/commonFrontend/actions'
import { EventModalExtendedFormValidationState } from '../../../core/eventModals/state'
import { EventFormProps, EventFormType } from './EventFormProps'

export type EventExtendedFormProps = EventFormProps<EventModalExtendedFormValidationState>

export const AtcIcon = styled(Icon)`
    height: 60px;
    width: 60px;
    background-color: ${props => props.bgColor};
    border-radius: 5px;
    align-content: center;
    padding: 12px;
    color: ${Color.White};
`

const dashboardOptions: Item<boolean>[] = [
    {
        value: false,
        label: 'Regular',
    },
    {
        value: true,
        label: 'Emergency',
    },
]

export const EventExtendedForm: FC<EventExtendedFormProps> = props => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.isApoc) {
            dispatch(loadCommonGroupsAction())
        }
    }, [props.isApoc])

    useEffect(() => {
        props.changeForm({ location: props.location?.location })
    }, [props.location])

    const renderAtcEventLayout = () => (
        <>
            <TwoColumnFormContainer
                mainContent={
                    <>
                        {!props.isEditingEvent && (
                            <ToggleButton
                                ButtonComponent={Toggle}
                                value={!!props.currentEvent.atcAlert}
                                onChange={value =>
                                    props.changeForm({
                                        atcAlert: value,
                                        visibility: AosEventVisibility.Public,
                                        atcFields: undefined,
                                    })
                                }
                                items={dashboardOptions}
                                defaultValue={dashboardOptions[1]}
                            />
                        )}
                        {props.isApoc && renderVisibility()}
                        {renderSeverity()}
                        {renderProcess()}
                        {renderCategory()}
                        {AtcEventForm(props)}
                        {renderExercise()}
                    </>
                }
                sideContent={
                    <>
                        {hasScheduling && renderScheduling()}
                        {renderLocation()}
                        {renderAttachments()}
                    </>
                }
            />
        </>
    )

    const renderLocation = () => {
        const { location, resetLocation } = props
        const navigateToMap = () => props.setViewMode(DialogView.Map)
        return (
            <LabeledFormElement
                errorMessage={translate('create-event-modal.location.error')}
                isError={props.form.error.location}
                isRequired={
                    props.currentEvent.process !== AosEventProcessType.Network &&
                    props.currentEvent.atcAlert
                }
                label={translate('create-event-modal.location.label')}
                marginBottom={12}
            >
                <Location
                    location={location?.location}
                    onEdit={navigateToMap}
                    onClear={resetLocation}
                />
            </LabeledFormElement>
        )
    }

    const renderAttachments = () => (
        <LabeledFormElement
            label={translate('create-event-modal.attachments.label')}
            marginBottom={12}
            seleniumLocation='attachments'
        >
            <AttachmentContainer
                attachmentsState={props.attachmentsState}
                attachmentsAction={props.attachmentsAction}
                editable
                gridSize={2}
            />
        </LabeledFormElement>
    )

    const renderScheduling = () => (
        <LabeledTimeRange
            keyPrefix='create-event-modal.scheduling'
            marginBottom={12}
            seleniumLocation='scheduling'
            value={{
                startTime: props.currentEvent.startTime,
                endTime: props.currentEvent.endTime,
            }}
            onChange={props.changeForm}
        />
    )

    const renderVisibility = () =>
        props.currentEvent.atcAlert ? (
            <LabeledFormElement
                label={translate('create-event-modal.emergency-group.label')}
                isError={props.form.error.aosUserGroup}
                marginBottom={12}
                isRequired
                seleniumLocation='visibility'
                errorMessage={translate('create-event-modal.emergency-group.error')}
                tooltip={
                    !(props.formType === EventFormType.EditEventModal) &&
                    translate('create-event-modal.emergency-group.tooltip')
                }
            >
                {props.formType === EventFormType.EditEventModal ? (
                    <UserGroupLabel>{props.currentEvent.aosUserGroup?.name}</UserGroupLabel>
                ) : (
                    <UserGroupSelector isAtc={props.currentEvent.atcAlert}>
                        {renderAosUserGroup()}
                    </UserGroupSelector>
                )}
            </LabeledFormElement>
        ) : (
            <LabeledFormElement
                label={translate('create-event-modal.visibility.label')}
                isError={props.form.error.aosUserGroup}
                marginBottom={12}
                isRequired
                seleniumLocation='visibility'
                errorMessage={translate('create-event-modal.visibility.error')}
            >
                <RadioGroup
                    name='event-visibility'
                    items={EnumValues.getValues<AosEventVisibility>(AosEventVisibility)}
                    formatter={translateEnum(AosEventVisibility, 'event-visibility')}
                    value={props.currentEvent.visibility}
                    onChange={changeVisibility}
                    fontSize={13}
                    variant={ThemeVariant.White}
                />
            </LabeledFormElement>
        )

    const renderSeverity = () => (
        <LabeledSeverity
            keyPrefix='create-event-modal.severity'
            seleniumLocation='severity'
            value={props.currentEvent.severity}
            severities={props.currentEvent.atcAlert ? atcEventSeverities : eventSeverities}
            onChange={changeSeverity}
            isError={props.form.error.severity}
            translateSeverity={props.currentEvent.atcAlert ? atcEventSeverityTitle : undefined}
            isRequired
        />
    )

    const renderProcess = () => {
        const statuses = props.currentEvent.atcAlert
            ? EnumValues.getValues<AosAtcEventProcessType>(AosAtcEventProcessType)
            : EnumValues.getValues<AosEventProcessType>(AosEventProcessType)
        return (
            <LabeledFormElement
                label={translate('create-event-modal.process.label')}
                isRequired
                isError={props.form.error.process}
                seleniumLocation='process'
            >
                <BlockRadioGroup
                    items={statuses as AosEventProcessType[]}
                    value={props.currentEvent.process}
                    marginBottom={8}
                    gridSize={3}
                    Renderer={({ isSelected, item, onSelect }) => (
                        <DefaultRadioRenderer
                            isSelected={isSelected}
                            label={eventProcessTitle(item)}
                            onSelect={onSelect}
                        />
                    )}
                    onChange={changeProcess}
                />
            </LabeledFormElement>
        )
    }

    const renderTitle = () => {
        const changeTitle = (title: string) => props.changeForm({ title })
        return (
            <LabeledInput
                keyPrefix='create-event-modal.title'
                marginBottom={12}
                onChangeText={changeTitle}
                value={props.currentEvent.title || ''}
                type='text'
                isRequired
                isError={props.form.error.title}
            />
        )
    }

    const renderCategory = () => (
        <LabeledSelect
            keyPrefix='create-event-modal.category'
            marginBottom={12}
            isRequired
            isError={props.form.error.category}
            seleniumLocation='category'
            items={categoryOptions}
            valueRenderer={renderScenarioOption}
            labelRenderer={eventScenarioTitle}
            value={props.currentEvent.category}
            onChange={changeCategory}
        />
    )

    const renderDescription = () => (
        <LabeledTextArea
            keyPrefix='create-event-modal.description'
            marginBottom={12}
            onChangeText={(description: string) => props.changeForm({ description })}
            value={props.currentEvent.description}
            resizeable
        />
    )

    const renderScenarioOption = (scenario: AosEventScenario) => (
        <Box row data-test-id={scenario}>
            <Icon
                iconSize={BlockSize.Std}
                svg={svgIconForName(eventScenarioIcon(scenario))}
                iconVariant={IconVariant.BlackGrey}
            />
            <Box paddingLeft={8}>{eventScenarioTitle(scenario)}</Box>
        </Box>
    )

    const changeSeverity = (severity: AosSeverity) => {
        const isSchedulable = isSeveritySchedulable(severity)
        const { currentEvent, isEditingEvent } = props
        props.changeForm({
            severity,
            startTime: isSchedulable || isEditingEvent ? currentEvent.startTime : undefined,
            endTime: isSchedulable ? currentEvent.endTime : undefined,
        })
    }

    const renderAosUserGroup = () => {
        const changeGroup = (aosUserGroup: AosUserGroup) => props.changeForm({ aosUserGroup })
        return (
            <DropdownAutocomplete
                value={props.currentEvent.aosUserGroup}
                items={props.groups}
                variant={DropdownVariant.White}
                labelRenderer={aosUserGroupName}
                placeholder={translate('create-event-modal.user-group-select')}
                onChange={changeGroup}
                outlined
            />
        )
    }

    const changeVisibility = (visibility: AosEventVisibility) => {
        props.changeForm({ visibility })
    }
    const changeProcess = (process: AosEventProcessType) => {
        props.changeForm({ process, category: undefined })
    }

    const changeCategory = (category?: AosEventScenario) => {
        props.changeForm({
            category,
            title:
                category && !props.currentEvent.atcAlert
                    ? eventScenarioTitleTemplate(category)
                    : props.currentEvent.title,
        })
    }

    const categoryOptions = useMemo(() => {
        const { process } = props.currentEvent
        const scenarios = process
            ? scenarioProcessMapping(props.currentEvent.atcAlert)[process]
            : []
        return scenarios.sort((a, b) => eventScenarioTitle(a).localeCompare(eventScenarioTitle(b)))
    }, [props.currentEvent])

    const hasScheduling = useMemo(() => {
        return isSeveritySchedulable(props.currentEvent.severity) && !props.currentEvent.atcAlert
    }, [props.currentEvent.severity, props.currentEvent.atcAlert])

    const renderEventLayout = () => (
        <TwoColumnFormContainer
            mainContent={
                <>
                    {!props.isEditingEvent && (
                        <ToggleButton
                            ButtonComponent={Toggle}
                            value={!!props.currentEvent.atcAlert}
                            onChange={value =>
                                props.changeForm({
                                    atcAlert: value,
                                    visibility: AosEventVisibility.Restricted,
                                })
                            }
                            items={dashboardOptions}
                        />
                    )}
                    {props.isApoc && renderVisibility()}
                    {renderSeverity()}
                    {renderProcess()}
                    {renderCategory()}
                    {renderTitle()}
                    {renderDescription()}
                </>
            }
            sideContent={
                <>
                    {hasScheduling && renderScheduling()}
                    {renderLocation()}
                    {renderAttachments()}
                </>
            }
        />
    )

    const renderExercise = () => (
        <Box marginTop={24} paddingBottom={24}>
            <Checkbox
                checked={props.currentEvent.exercise || false}
                onChange={exercise => props.changeForm({ exercise })}
            >
                <Text color={Color.Black} size={14} paddingLeft={8}>
                    {translate('create-event-modal.exercise')}
                </Text>
            </Checkbox>
            <Text color={Color.Grey2} size={12} marginTop={10}>
                {translate('create-event-modal.exercise-info')}
            </Text>
        </Box>
    )

    useEffect(() => {
        if (
            props.currentEvent.atcAlert &&
            !props.currentEvent.category &&
            props.currentEvent.process === AosEventProcessType.HelOthers
        ) {
            props.changeForm({ category: AosEventScenario.SafetySecurity })
        } else if (
            props.currentEvent.atcAlert &&
            !props.isEditingEvent &&
            !props.currentEvent.process
        ) {
            props.changeForm({ category: undefined })
        }
    }, [props.currentEvent.atcAlert, props.currentEvent.process])

    return props.currentEvent.atcAlert ? renderAtcEventLayout() : renderEventLayout()
}

interface UserGroupProps {
    isAtc?: boolean
}

const UserGroupSelector = styled(Box)<UserGroupProps>`
    margin-top: ${({ isAtc }) => (isAtc ? 0 : 15)}px;
`

const UserGroupLabel = styled.p`
    font-size: 14px;
    font-weight: 600;
    color: ${Color.TimelineBackground};
`

const BaseButton = styled('button')<Omit<FormButtonProps, 'label'>>`
    padding: 10px 20px;
    flex: 1;
    border-radius: 4px;
    border: 1px solid
        ${props => (props.variant === FormButtonVariant.Red ? Color.PrimaryPale : Color.Grey4)};
    background-color: ${props =>
        props.variant === FormButtonVariant.Red ? Color.PaleBlue : Color.White};
`

const Toggle = ({ label, ...rest }: FormButtonProps) => (
    <BaseButton {...rest}>
        <Label active={rest.variant === FormButtonVariant.Red}>{label}</Label>
    </BaseButton>
)
const Label = styled('label')<{ active: boolean }>`
    font-size: 12px;
    color: ${props => (props.active ? Color.PrimaryDark : Color.Grey5)};
    font-weight: 500;
`
