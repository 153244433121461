import { Box } from 'aos-ui/src/components/base/Box'
import { Header, HeaderVariant } from 'aos-ui/src/components/header/Header'
import { VariantHeaderRender } from 'aos-ui-map/src/components/map/header/ModalMapHeader'
import { MapLayersVisibility } from 'aos-ui-map/src/components/map/layersVisibility/MapLayersVisibility'
import { MapAction, switchMapAction, toggleLayersSidebarAction } from 'aos-ui-map/src/core/actions'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { airportMapMapParentAction } from '../../core/airportMap/actions'
import { State } from '../../core/state'

export const AirportMapHeader = () => {
    const mapState = useSelector((state: State) => state.airportMap)
    const dispatch = useDispatch()
    const mapAction = (v: MapAction) => dispatch(airportMapMapParentAction(v))
    return (
        <Header variant={HeaderVariant.Dialog} paddingHorizontal={30}>
            <Box flex={1}>
                <VariantHeaderRender
                    location={mapState.siteLocationCustomization}
                    mapVariant={mapState.variant}
                    onChangeVariant={v => mapAction(switchMapAction(v))}
                />
            </Box>
            <MapLayersVisibility
                layersSidebarOpen={mapState.layersSidebarOpen}
                toggleLayersSidebar={v => mapAction(toggleLayersSidebarAction(v))}
            />
        </Header>
    )
}
