import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { getBackgroundColorFromSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { AosEventProcessType } from 'aos-services/src/services/events/types/AosEventProcessType'
import {
    PrivateChannelParticipant,
    SplittedParticipants,
} from 'aos-services/src/services/firebaseEvents/types/PrivateChannelParticipant'
import { AttachmentReadonlySection } from 'aos-ui/src/components/attachment/AttachmentReadonlySection'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { Item, ToggleButton } from 'aos-ui/src/components/buttons/ToggleButton'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { AtcIcon } from '../../eventModals/partial/EventExtendedForm'
import { AddIconComponent } from './AddIconComponent'
import { CommanderList } from './CommanderList'

export interface AtcEventInfoContentProps {
    event: AosEvent
    editEvent?(): void
    dashboardState: DashboardOption
    onChangeDashboard: (value: DashboardOption) => void
    participants: SplittedParticipants
}

export enum DashboardOption {
    DETAILS,
    DASHBOARD,
}

export const AtcEventInfoContent: FC<AtcEventInfoContentProps> = ({
    event,
    editEvent,
    dashboardState,
    onChangeDashboard,
    participants,
}) => {
    const options: Item<DashboardOption>[] = [
        { label: translate('atc-event-detail.details'), value: DashboardOption.DETAILS },
        { label: translate('atc-event-detail.dashboard'), value: DashboardOption.DASHBOARD },
    ]

    return (
        <>
            <TitleBox row>
                <AtcIcon
                    bgColor={getBackgroundColorFromSeverity(event.severity)}
                    svg={SvgIcon.Atc}
                />
                <Value>{event.title}</Value>
            </TitleBox>

            {event.process !== AosEventProcessType.Network && (
                <ToggleButton value={dashboardState} onChange={onChangeDashboard} items={options} />
            )}

            {dashboardState === DashboardOption.DASHBOARD && (
                <SectionWithDividers>
                    <CommanderList
                        participants={[
                            ...(participants?.[1] ?? []),
                            participants?.[0] as PrivateChannelParticipant,
                        ]}
                    />
                </SectionWithDividers>
            )}

            <Section>
                <Row row>
                    <Label>{translate('atc-event-detail.created-on')}</Label>
                    <Value>{event.startTime.format('DD.MM.YYYY HH:mm')}</Value>
                </Row>
                <Row row>
                    <Label>{translate('atc-event-detail.modified-at')}</Label>
                    <Value>{event.modifiedAt?.format('DD.MM.YYYY HH:mm')}</Value>
                </Row>
                <Row row>
                    <Label>{translate('atc-event-detail.category')}</Label>
                    <Value>{event.category as string}</Value>
                </Row>
                <Row row>
                    <Label>{translate('atc-event-detail.location')}</Label>
                    <Value>{event.hasLocation ? 'Added' : ''}</Value>
                </Row>
                <LastRow row>
                    <Label>{translate('atc-event-detail.incident-type')}</Label>
                    <Value>{event.atcFields?.incidentType}</Value>
                </LastRow>
            </Section>
            <Section>
                <Row row>
                    <Icon
                        svg={SvgIcon.CurrentTime}
                        iconSize={BlockSize.Small}
                        color={Color.GreyLight}
                    />
                    <IconLabel>{translate('atc-event-detail.eta')}</IconLabel>
                    {event.atcFields?.eta ? (
                        <Value>{event.atcFields.eta}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </Row>
                <Row row>
                    <Icon
                        svg={SvgIcon.Location}
                        iconSize={BlockSize.Small}
                        color={Color.GreyLight}
                    />
                    <IconLabel>{translate('atc-event-detail.location-name')}</IconLabel>
                    {event.atcFields?.locationName ? (
                        <Value>{event.atcFields?.locationName}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </Row>
                <Row row>
                    <Icon
                        svg={SvgIcon.Flights}
                        iconSize={BlockSize.Small}
                        color={Color.GreyLight}
                    />
                    <IconLabel>{translate('atc-event-detail.callsign')}</IconLabel>
                    {event.atcFields?.callSign ? (
                        <Value>{event.atcFields?.callSign}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </Row>
                <Row row>
                    <Icon
                        svg={SvgIcon.Flights}
                        iconSize={BlockSize.Small}
                        color={Color.GreyLight}
                    />
                    <IconLabel>{translate('atc-event-detail.flight-number')}</IconLabel>
                    {event.atcFields?.flightNumber ? (
                        <Value>{event.atcFields?.flightNumber}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </Row>
                <Row row>
                    <Icon svg={SvgIcon.Route} iconSize={BlockSize.Small} color={Color.GreyLight} />
                    <IconLabel>{translate('atc-event-detail.route')}</IconLabel>
                    {event.atcFields?.route ? (
                        <Value>{event.atcFields?.route}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </Row>
                <Row row>
                    <Icon
                        svg={SvgIcon.Flights}
                        iconSize={BlockSize.Small}
                        color={Color.GreyLight}
                    />
                    <IconLabel>{translate('atc-event-detail.aircraft-type')}</IconLabel>
                    {event.atcFields?.aircraftModel ? (
                        <Value>{event.atcFields?.aircraftModel}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </Row>
                <Row row>
                    <Icon
                        svg={SvgIcon.OperationalForecastPassaners}
                        iconSize={BlockSize.Small}
                        color={Color.GreyLight}
                    />
                    <IconLabel>{translate('atc-event-detail.pax-number')}</IconLabel>
                    {event.atcFields?.numberOfPax ? (
                        <Value>{event.atcFields?.numberOfPax}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </Row>
                <LastRow row>
                    <Icon
                        svg={SvgIcon.OperationalForecastPassaners}
                        iconSize={BlockSize.Small}
                        color={Color.GreyLight}
                    />
                    <IconLabel>{translate('atc-event-detail.crew-number')}</IconLabel>
                    {event.atcFields?.numberOfCrew ? (
                        <Value>{event.atcFields?.numberOfCrew}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </LastRow>
            </Section>
            <Section>
                <Row row>
                    <Icon svg={SvgIcon.Atc} iconSize={BlockSize.Tiny} color={Color.GreyLight} />
                    <IconLabel>{translate('atc-event-detail.dgr')}</IconLabel>
                    {event.atcFields?.dgr ? (
                        <Value>{event.atcFields?.dgr}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </Row>
                <LastRow row>
                    <Icon svg={SvgIcon.Gas} iconSize={BlockSize.Small} color={Color.GreyLight} />
                    <IconLabel>{translate('atc-event-detail.fuel')}</IconLabel>
                    {event.atcFields?.fuel ? (
                        <Value>{event.atcFields?.fuel}</Value>
                    ) : (
                        <AddIconComponent action={editEvent} />
                    )}
                </LastRow>
            </Section>
            <LastSection>
                <Row>
                    <Row row>
                        <Icon
                            svg={SvgIcon.MoreInfo}
                            iconSize={BlockSize.Tiny}
                            color={Color.GreyLight}
                        />
                        <IconLabel>{translate('atc-event-detail.more-info')}</IconLabel>
                    </Row>
                    {event.atcFields?.moreInfo ? (
                        <MoreInfo>{event.atcFields?.moreInfo}</MoreInfo>
                    ) : (
                        <AddIconComponent action={editEvent} marginTop={4} />
                    )}
                </Row>
                <LastRow>
                    <Row row>
                        <Icon
                            svg={SvgIcon.Attachment}
                            iconSize={BlockSize.Tiny}
                            color={Color.GreyLight}
                        />
                        <IconLabel>{translate('atc-event-detail.attachment')}</IconLabel>
                    </Row>
                    <AttachmentReadonlySection
                        attachments={event.allAttachments}
                        variant={ThemeVariant.Black}
                        size={LabeledTextElementSize.Small}
                        noLabel
                    />
                </LastRow>
            </LastSection>
        </>
    )
}

const TitleBox = styled(Box)`
    padding-bottom: 16px;
`

const Section = styled(Box)`
    padding-top: 16px;
    border-bottom: 1px solid ${Color.ChartBase};
`

const SectionWithDividers = styled(Box)`
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${Color.ChartBase};
    border-top: 1px solid ${Color.ChartBase};
`

const LastSection = styled(Section)`
    border-bottom: none;
`

const Row = styled(Box)`
    margin-bottom: 12px;
`
const LastRow = styled(Box)`
    margin-bottom: 16px;
    word-break: break-word;
`

const Label = styled(Text)`
    font-size: 14px;
    color: ${Color.DisabledText};
    font-weight: 400;
`

const IconLabel = styled(Text)`
    font-size: 14px;
    color: ${Color.DisabledText};
    font-weight: 400;
    margin-left: 9px;
`

const Value = styled(Text)`
    font-size: 14px;
    color: ${Color.White};
    font-weight: 700;
    margin-left: 8px;
`

const MoreInfo = styled(Text)`
    font-size: 14px;
    color: ${Color.White};
    font-weight: 700;
    margin-top: 6px;
`
