import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { GeoJSON } from 'ol/format'
import { Layer } from 'ol/layer'
import VektorEmbeddedAirportLite from 'vektor-embedded-airport-lite'

import { MapProvider } from '../Types'

export class VektorProvider implements MapProvider {
    private _instance: VektorEmbeddedAirportLite | null = null
    public async loadLayer(layerId: string) {
        try {
            return await VektorEmbeddedAirportLite.fetchVectorLayerData(layerId)
        } catch (e) {
            logger.handleError(e as Error)
        }
    }

    public initialise(): MapProvider {
        this._instance = new VektorEmbeddedAirportLite()
        return this
    }

    public async mount(props: { bcContainer: string; bcOptions: any }) {
        if (this._instance) {
            await this._instance.init()
            this._instance.mount(props.bcContainer, props.bcOptions)
        } else {
            throw new Error('mount: Provider is not initialized')
        }
    }

    public async addMapLayer(layerId: string) {
        if (this._instance) {
            return this._instance.addMapLayer(layerId)
        } else {
            throw new Error('addMapLayer: Provider is not initialized')
        }
    }

    public removeMapLayer(layerId: string) {
        if (this._instance) {
            return this._instance.removeMapLayer(layerId)
        } else {
            throw new Error('removeMapLayer: Provider is not initialized')
        }
    }

    public async addMapLayerAsGeoJson(
        layerId: string,
        data: GeoJSON,
        minZoom: number,
        maxZoom: number,
        scale: number,
        zIndex: number,
    ): Promise<Layer> {
        if (this._instance) {
            return this._instance.addMapLayerAsGeoJson(
                layerId,
                data,
                minZoom,
                maxZoom,
                scale,
                zIndex,
            )
        } else {
            throw new Error('addMapLayerAsGeoJson: Provider is not initialized')
        }
    }

    public async addMapLayerAsVector(layerId: string): Promise<Layer> {
        if (this._instance) {
            return this._instance.addMapLayerAsVector(layerId)
        } else {
            throw new Error('addMapLayerAsVector: Provider is not initialized')
        }
    }
}
