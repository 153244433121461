import { ActionModalContent } from 'aos-components/src/components/modal/ActionModal/ActionModalContent'
import { minutesToTaskDuration } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskUpdateMode } from 'aos-services/src/services/tasks/types/payload/BaseTaskFormPayload'
import { TaskDuration } from 'aos-services/src/services/tasks/types/TaskStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { Toggle } from 'aos-ui/src/components/form/checkbox/Toggle'
import { StartAndEndTimePicker } from 'aos-ui/src/components/form/timePicker/StartAndEndTimePicker'
import { TimePicker } from 'aos-ui/src/components/form/timePicker/TimePicker'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { State } from '../../../../core/state'
import { closeTaskDurationModalAction } from '../../../../core/tasks/actions'

const DEFAULT_DURATION = { hour: 1, minute: 0, startTime: undefined, endTime: undefined }
export const TaskDurationModal = () => {
    const [isSchedulingAccuracy, setIsSchedulingAccuracy] = useState(false)
    const dispatch = useDispatch()
    const isOpen = useSelector((state: State) => state.taskManager.isTaskDurationModalOpen)
    const task = useSelector((state: State) => state.taskManager.selectedTask)
    const mode = useSelector((state: State) => state.taskManager.modeState)
    const onClose = () => {
        dispatch(closeTaskDurationModalAction(null))
        form.reset(DEFAULT_DURATION)
        setIsSchedulingAccuracy(false)
    }
    const footer = () => (
        <Box justify='space-around' row>
            <FormButton
                onClick={() => form.handleSubmit(onSubmit)()}
                label={translate('task-duration.save')}
                variant={FormButtonVariant.Blue}
            />
        </Box>
    )
    const form = useForm<TaskDuration>({
        defaultValues: DEFAULT_DURATION,
    })

    const onSubmit = (data: TaskDuration) => {
        dispatch(closeTaskDurationModalAction(data))
        form.reset(DEFAULT_DURATION)
        setIsSchedulingAccuracy(false)
    }

    useEffect(() => {
        if (task?.spendTimeInMinutes) {
            const duration = minutesToTaskDuration(task.spendTimeInMinutes)
            form.reset(duration)
        } else {
            form.reset(DEFAULT_DURATION)
        }
    }, [task?.spendTimeInMinutes])

    const toggle = (enabled: boolean) => {
        if (enabled) {
            form.setValue('startTime', task?.startTime)
            form.setValue('endTime', task?.endTime)
        } else {
            form.setValue('startTime', undefined)
            form.setValue('endTime', undefined)
        }
        setIsSchedulingAccuracy(enabled)
    }

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal
                    width={380}
                    isOpen={isOpen}
                    title={translate('task-duration.title')}
                    modalKind={ModalKind.Auto}
                    closeAction={onClose}
                >
                    <ActionModalContent Footer={footer} className='padding-horizontal--x-large'>
                        <Text color={Color.TimelineBackground} size={14}>
                            {translate('task-duration.subtitle')}
                        </Text>
                        <Box paddingVertical={12} paddingHorizontal={4}>
                            <TimePicker />
                        </Box>
                        <Box justify='space-between' marginBottom={16} row>
                            <Text size={16} color={Color.Black}>
                                {translate('task-duration.scheduling-accuracy')}
                            </Text>
                            <Toggle
                                value={isSchedulingAccuracy}
                                onChange={toggle}
                                disabled={mode === TaskUpdateMode.Parent}
                            />
                        </Box>
                        {isSchedulingAccuracy && (
                            <>
                                <Box>
                                    <Text color={Color.Grey} size={14}>
                                        {translate('task-scheduling-accuracy.subtitle')}
                                    </Text>
                                </Box>
                                <Box paddingVertical={12}>
                                    <StartAndEndTimePicker />
                                </Box>
                            </>
                        )}
                    </ActionModalContent>
                </Modal>
            </form>
        </FormProvider>
    )
}
