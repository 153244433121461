import { BimLayersState } from 'aos-services/src/core/bimLayersData/state'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { useEffect } from 'react'

export const useLoadBimLayers = (
    variant: MapVariant,
    bimMapLayers: BimLayersState,
    loadBimLayersDataAction: () => void,
) => {
    useEffect(() => {
        if (variant !== MapVariant.World) {
            if (bimMapLayers.isLoading) {
                loadBimLayersDataAction()
            }
        }
    }, [bimMapLayers, variant])
}
