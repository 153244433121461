import { appConfig } from 'appConfig'

import { AosAirport } from '../../flightInformation/types/AosAirport'
import { AosEventProcessType } from './AosEventProcessType'
import { AosEventScenarioIcon } from './AosEventScenarioIcon'

const AOS_AIRPORTS = {
    Ivl: 'IVL',
    Joe: 'JOE',
    Jyv: 'JYV',
    Kaj: 'KAJ',
    Kao: 'KAO',
    Kem: 'KEM',
    Kev: 'KEV',
    Kok: 'KOK',
    Ktt: 'KTT',
    Kuo: 'KUO',
    Mhq: 'MHQ',
    Oul: 'OUL',
    Por: 'POR',
    Rvn: 'RVN',
    Svl: 'SVL',
    Tku: 'TKU',
    Tmp: 'TMP',
    Uti: 'UTI',
    Vaa: 'VAA',
}

const AOIMS_AIRPORTS = {
    Eka: 'EKA',
    Eke: 'EKE',
    Eku: 'EKU',
    Epu: 'EPU',
    Eru: 'ERU',
    Tay: 'TAY',
    Tll: 'TLL',
}

const AirportScenarioCustomized: Record<string, any> = {
    AOS: AOS_AIRPORTS,
    AOIMS: AOIMS_AIRPORTS,
}

export const AosEventScenario = {
    // HEL_BAGS
    BaggageArrival: 'BAGGAGE_ARRIVAL',
    BaggageDepartures: 'BAGGAGE_DEPARTURES',
    BaggageHandlingSystem: 'BAGGAGE_HANDLING_SYSTEM',
    BaggageTransfer: 'BAGGAGE_TRANSFER',
    BagsGeneralInformation: 'BAGS_GENERAL_INFORMATION',

    // HEL_OTHERS
    AirlineGroundHandling: 'AIRLINE_GROUND_HANDLING',
    ElectricityDistribution: 'ELECTRICITY_DISTRIBUTION',
    InformationManagementItSystems: 'INFORMATION_MANAGEMENT_IT_SYSTEMS',
    Maintenance: 'MAINTENANCE',
    Media: 'MEDIA',
    WaterDistribution: 'WATER_DISTRIBUTION',
    SafetySecurity: 'SAFETY_SECURITY',
    OtherNotifications: 'OTHER_NOTIFICATIONS',

    // HEL_PAX
    AirportParking: 'AIRPORT_PARKING',
    BorderControl: 'BORDER_CONTROL',
    Bus: 'BUS',
    CheckIn: 'CHECK_IN',
    CheckInKiosks: 'CHECK_IN_KIOSKS',
    ElevatorsEscalators: 'ELEVATORS_ESCALATORS',
    Gates: 'GATES',
    Prm: 'PRM',
    Roads: 'ROADS',
    SecurityControl: 'SECURITY_CONTROL',
    Services: 'SERVICES',
    Taxi: 'TAXI',
    Train: 'TRAIN',
    PaxGeneralInformation: 'PAX_GENERAL_INFORMATION',
    TerminalPeakTimes: 'TERMINAL_PEAK_TIMES',
    TerminalFacilities: 'TERMINAL_FACILITIES',
    PassengerServices: 'PASSENGER_SERVICES',

    // HEL_TURNAROUND
    AircraftStands: 'AIRCRAFT_STANDS',
    AirfieldMaintenance: 'AIRFIELD_MAINTENANCE',
    ApronBuses: 'APRON_BUSES',
    AtcReducedCapacity: 'ATC_REDUCED_CAPACITY',
    DeIcing: 'DE_ICING',
    DivertedFlights: 'DIVERTED_FLIGHTS',
    Lightning: 'LIGHTNING',
    Lvp: 'LVP',
    PassengerBridges: 'PASSENGER_BRIDGES',
    Rain: 'RAIN',
    Runways: 'RUNWAYS',
    SlipperyApron: 'SLIPPERY_APRON',
    Snow: 'SNOW',
    Taxiways: 'TAXIWAYS',
    WeatherAlert: 'WEATHER_ALERT',
    Wind: 'WIND',
    TrafficStatus: 'TRAFFIC_STATUS',
    HeavyDelays: 'HEAVY_DELAYS',
    TurnaroundGeneralInformation: 'TURNAROUND_GENERAL_INFORMATION',

    // HEL_AIRPORT_CONSTRUCTION
    ApronConstruction: 'APRON_CONSTRUCTION',
    LandsideConstruction: 'LANDSIDE_CONSTRUCTION',
    TerminalConstruction: 'TERMINAL_CONSTRUCTION',

    // NETWORK
    ...AirportScenarioCustomized[appConfig.theme],
    World: 'WORLD',
    NetworkGeneralInformation: 'NETWORK_GENERAL_INFORMATION',
}

export type AosEventScenario = keyof typeof AosEventScenario

const networkScenarioToAirport: Record<
    string,
    Partial<Record<AosEventScenario & keyof typeof AirportScenarioCustomized, AosAirport>>
> = {
    AOS: {
        [AosEventScenario.Ivl]: AosAirport.IVL,
        [AosEventScenario.Joe]: AosAirport.JOE,
        [AosEventScenario.Jyv]: AosAirport.JYV,
        [AosEventScenario.Kaj]: AosAirport.KAJ,
        [AosEventScenario.Kao]: AosAirport.KAO,
        [AosEventScenario.Kem]: AosAirport.KEM,
        [AosEventScenario.Kev]: AosAirport.KEV,
        [AosEventScenario.Kok]: AosAirport.KOK,
        [AosEventScenario.Ktt]: AosAirport.KTT,
        [AosEventScenario.Kuo]: AosAirport.KUO,
        [AosEventScenario.Mhq]: AosAirport.MHQ,
        [AosEventScenario.Oul]: AosAirport.OUL,
        [AosEventScenario.Por]: AosAirport.POR,
        [AosEventScenario.Rvn]: AosAirport.RVN,
        [AosEventScenario.Svl]: AosAirport.SVL,
        [AosEventScenario.Tku]: AosAirport.TKU,
        [AosEventScenario.Tmp]: AosAirport.TMP,
        [AosEventScenario.Uti]: AosAirport.UTI,
        [AosEventScenario.Vaa]: AosAirport.VAA,
    },
    AOIMS: {
        [AosEventScenario.Tll]: AosAirport.TLL,
        [AosEventScenario.Eru]: AosAirport.ERU,
        [AosEventScenario.Eku]: AosAirport.EKU,
        [AosEventScenario.Eka]: AosAirport.EKA,
        [AosEventScenario.Eke]: AosAirport.EKE,
        [AosEventScenario.Epu]: AosAirport.EPU,
        [AosEventScenario.Tay]: AosAirport.TAY,
    },
}

export const getAirportFromNetworkScenario = (
    scenario: AosEventScenario & keyof typeof AirportScenarioCustomized,
) => networkScenarioToAirport[appConfig.theme][scenario]

export const isAirportNetworkScenario = (
    scenario: AosEventScenario & keyof typeof AirportScenarioCustomized,
) => !!networkScenarioToAirport[appConfig.theme][scenario]

export const airportScenarios: Record<string, AosEventScenarioIcon> = {
    AOS: {
        [AosEventScenario.Ivl]: AosEventScenarioIcon.Ivl,
        [AosEventScenario.Joe]: AosEventScenarioIcon.Joe,
        [AosEventScenario.Jyv]: AosEventScenarioIcon.Jyv,
        [AosEventScenario.Kaj]: AosEventScenarioIcon.Kaj,
        [AosEventScenario.Kao]: AosEventScenarioIcon.Kao,
        [AosEventScenario.Kem]: AosEventScenarioIcon.Kem,
        [AosEventScenario.Kev]: AosEventScenarioIcon.Kev,
        [AosEventScenario.Kok]: AosEventScenarioIcon.Kok,
        [AosEventScenario.Ktt]: AosEventScenarioIcon.Ktt,
        [AosEventScenario.Kuo]: AosEventScenarioIcon.Kuo,
        [AosEventScenario.Mhq]: AosEventScenarioIcon.Mhq,
        [AosEventScenario.Oul]: AosEventScenarioIcon.Oul,
        [AosEventScenario.Por]: AosEventScenarioIcon.Por,
        [AosEventScenario.Rvn]: AosEventScenarioIcon.Rvn,
        [AosEventScenario.Svl]: AosEventScenarioIcon.Svl,
        [AosEventScenario.Tku]: AosEventScenarioIcon.Tku,
        [AosEventScenario.Tmp]: AosEventScenarioIcon.Tmp,
        [AosEventScenario.Uti]: AosEventScenarioIcon.Uti,
        [AosEventScenario.Vaa]: AosEventScenarioIcon.Vaa,
    },
    AOIMS: {
        [AosEventScenario.Tll]: AosEventScenarioIcon.Tll,
        [AosEventScenario.Eru]: AosEventScenarioIcon.Eru,
        [AosEventScenario.Eku]: AosEventScenarioIcon.Eku,
        [AosEventScenario.Eka]: AosEventScenarioIcon.Eka,
        [AosEventScenario.Eke]: AosEventScenarioIcon.Eke,
        [AosEventScenario.Epu]: AosEventScenarioIcon.Epu,
        [AosEventScenario.Tay]: AosEventScenarioIcon.Tay,
    },
}

const scenarioIconMapping: Record<AosEventScenario, AosEventScenarioIcon> = {
    // HEL_BAGS
    [AosEventScenario.BaggageDepartures]: AosEventScenarioIcon.BaggageDepartures,
    [AosEventScenario.BaggageTransfer]: AosEventScenarioIcon.BaggageTransfer,
    [AosEventScenario.BaggageArrival]: AosEventScenarioIcon.BaggageArrival,
    [AosEventScenario.BaggageHandlingSystem]: AosEventScenarioIcon.BaggageHandling,
    [AosEventScenario.BagsGeneralInformation]: AosEventScenarioIcon.BaggageGeneralInfo,

    // HEL_OTHERS
    [AosEventScenario.AirlineGroundHandling]: AosEventScenarioIcon.AirlineGroundHandling,
    [AosEventScenario.ElectricityDistribution]: AosEventScenarioIcon.Electricity,
    [AosEventScenario.InformationManagementItSystems]: AosEventScenarioIcon.InfoManagement,
    [AosEventScenario.Maintenance]: AosEventScenarioIcon.Maintenance,
    [AosEventScenario.Media]: AosEventScenarioIcon.Media,
    [AosEventScenario.WaterDistribution]: AosEventScenarioIcon.WaterDistribution,
    [AosEventScenario.SafetySecurity]: AosEventScenarioIcon.SafetySecurity,
    [AosEventScenario.OtherNotifications]: AosEventScenarioIcon.Other,

    // HEL_PAX
    [AosEventScenario.AirportParking]: AosEventScenarioIcon.ParkingArea,
    [AosEventScenario.BorderControl]: AosEventScenarioIcon.BorderControl,
    [AosEventScenario.Bus]: AosEventScenarioIcon.Bus,
    [AosEventScenario.CheckIn]: AosEventScenarioIcon.CheckIn,
    [AosEventScenario.CheckInKiosks]: AosEventScenarioIcon.CheckinAutomat,
    [AosEventScenario.ElevatorsEscalators]: AosEventScenarioIcon.Elevators,
    [AosEventScenario.Gates]: AosEventScenarioIcon.Gates,
    [AosEventScenario.Prm]: AosEventScenarioIcon.Prm,
    [AosEventScenario.Roads]: AosEventScenarioIcon.Roads,
    [AosEventScenario.SecurityControl]: AosEventScenarioIcon.SecurityGates,
    [AosEventScenario.Services]: AosEventScenarioIcon.Services,
    [AosEventScenario.Taxi]: AosEventScenarioIcon.Taxi,
    [AosEventScenario.Train]: AosEventScenarioIcon.Train,
    [AosEventScenario.PaxGeneralInformation]: AosEventScenarioIcon.PaxGeneralInfo,
    [AosEventScenario.TerminalPeakTimes]: AosEventScenarioIcon.TerminalPeakTimes,
    [AosEventScenario.TerminalFacilities]: AosEventScenarioIcon.TerminalFacilities,
    [AosEventScenario.PassengerServices]: AosEventScenarioIcon.PassengerServices,

    // HEL_TURNAROUND
    [AosEventScenario.AircraftStands]: AosEventScenarioIcon.AircraftStands,
    [AosEventScenario.AirfieldMaintenance]: AosEventScenarioIcon.AirfiledMaintanace,
    [AosEventScenario.ApronBuses]: AosEventScenarioIcon.ApronBuses,
    [AosEventScenario.AtcReducedCapacity]: AosEventScenarioIcon.AtcReducedCapacity,
    [AosEventScenario.DeIcing]: AosEventScenarioIcon.DeIcing,
    [AosEventScenario.DivertedFlights]: AosEventScenarioIcon.DivergedFlights,
    [AosEventScenario.Lightning]: AosEventScenarioIcon.Lightning,
    [AosEventScenario.Lvp]: AosEventScenarioIcon.Lvp,
    [AosEventScenario.PassengerBridges]: AosEventScenarioIcon.PassangerBridges,
    [AosEventScenario.Rain]: AosEventScenarioIcon.Rain,
    [AosEventScenario.Runways]: AosEventScenarioIcon.Rwy,
    [AosEventScenario.SlipperyApron]: AosEventScenarioIcon.SlipperyApron,
    [AosEventScenario.Snow]: AosEventScenarioIcon.Snow,
    [AosEventScenario.Taxiways]: AosEventScenarioIcon.Taxiways,
    [AosEventScenario.WeatherAlert]: AosEventScenarioIcon.Wa,
    [AosEventScenario.Wind]: AosEventScenarioIcon.Wind,
    [AosEventScenario.TrafficStatus]: AosEventScenarioIcon.TrafficStatus,
    [AosEventScenario.HeavyDelays]: AosEventScenarioIcon.HeavyDelays,
    [AosEventScenario.TurnaroundGeneralInformation]: AosEventScenarioIcon.TurnaroundGeneralInfo,

    // HEL_AIRPORT_CONSTRUCTION
    [AosEventScenario.ApronConstruction]: AosEventScenarioIcon.ApronConstruction,
    [AosEventScenario.LandsideConstruction]: AosEventScenarioIcon.LandSideConstruction,
    [AosEventScenario.TerminalConstruction]: AosEventScenarioIcon.TerminalConstruction,

    // NETWORK
    ...airportScenarios[appConfig.theme],
    [AosEventScenario.World]: AosEventScenarioIcon.World,
    [AosEventScenario.NetworkGeneralInformation]: AosEventScenarioIcon.NetworkGeneralInfo,
}

const scenarioMap = (isAtc: boolean | undefined): Record<string, AosEventScenarioIcon> => {
    return {
        AOS: isAtc
            ? [
                  AosEventScenario.Ivl,
                  AosEventScenario.Joe,
                  AosEventScenario.Jyv,
                  AosEventScenario.Kaj,
                  AosEventScenario.Kao,
                  AosEventScenario.Kem,
                  AosEventScenario.Kev,
                  AosEventScenario.Kok,
                  AosEventScenario.Ktt,
                  AosEventScenario.Kuo,
                  AosEventScenario.Mhq,
                  AosEventScenario.Oul,
                  AosEventScenario.Por,
                  AosEventScenario.Rvn,
                  AosEventScenario.Svl,
                  AosEventScenario.Tku,
                  AosEventScenario.Tmp,
                  AosEventScenario.Uti,
                  AosEventScenario.Vaa,
              ]
            : [
                  AosEventScenario.Ivl,
                  AosEventScenario.Joe,
                  AosEventScenario.Jyv,
                  AosEventScenario.Kaj,
                  AosEventScenario.Kao,
                  AosEventScenario.Kem,
                  AosEventScenario.Kev,
                  AosEventScenario.Kok,
                  AosEventScenario.Ktt,
                  AosEventScenario.Kuo,
                  AosEventScenario.Mhq,
                  AosEventScenario.Oul,
                  AosEventScenario.Por,
                  AosEventScenario.Rvn,
                  AosEventScenario.Svl,
                  AosEventScenario.Tku,
                  AosEventScenario.Tmp,
                  AosEventScenario.Uti,
                  AosEventScenario.Vaa,
                  AosEventScenario.World,
                  AosEventScenario.NetworkGeneralInformation,
              ],
        AOIMS: [
            AosEventScenario.Tll,
            AosEventScenario.Eru,
            AosEventScenario.Eku,
            AosEventScenario.Eka,
            AosEventScenario.Eke,
            AosEventScenario.Epu,
            AosEventScenario.Tay,
            AosEventScenario.World,
            AosEventScenario.NetworkGeneralInformation,
        ],
    }
}

export const eventScenarioIcon = (e: AosEventScenario): AosEventScenarioIcon => {
    return scenarioIconMapping[e] || 'Info'
}
export const scenarioProcessMapping = (
    isAtc: boolean | undefined,
): Record<AosEventProcessType, AosEventScenario[]> => ({
    [AosEventProcessType.HelBags]: [
        AosEventScenario.BaggageArrival,
        AosEventScenario.BaggageDepartures,
        AosEventScenario.BaggageHandlingSystem,
        AosEventScenario.BaggageTransfer,
        AosEventScenario.BagsGeneralInformation,
    ],

    [AosEventProcessType.HelPax]: [
        AosEventScenario.AirportParking,
        AosEventScenario.BorderControl,
        AosEventScenario.Bus,
        AosEventScenario.CheckIn,
        AosEventScenario.CheckInKiosks,
        AosEventScenario.ElevatorsEscalators,
        AosEventScenario.Gates,
        AosEventScenario.Prm,
        AosEventScenario.Roads,
        AosEventScenario.SecurityControl,
        AosEventScenario.Services,
        AosEventScenario.Taxi,
        AosEventScenario.Train,
        AosEventScenario.PaxGeneralInformation,
        AosEventScenario.TerminalPeakTimes,
        AosEventScenario.TerminalFacilities,
        AosEventScenario.PassengerServices,
    ],

    [AosEventProcessType.HelTurnaround]: [
        AosEventScenario.AircraftStands,
        AosEventScenario.AirfieldMaintenance,
        AosEventScenario.ApronBuses,
        AosEventScenario.AtcReducedCapacity,
        AosEventScenario.DeIcing,
        AosEventScenario.DivertedFlights,
        AosEventScenario.Lightning,
        AosEventScenario.Lvp,
        AosEventScenario.PassengerBridges,
        AosEventScenario.Rain,
        AosEventScenario.Runways,
        AosEventScenario.SlipperyApron,
        AosEventScenario.Snow,
        AosEventScenario.Taxiways,
        AosEventScenario.WeatherAlert,
        AosEventScenario.Wind,
        AosEventScenario.TrafficStatus,
        AosEventScenario.HeavyDelays,
        AosEventScenario.TurnaroundGeneralInformation,
    ],
    [AosEventProcessType.HelOthers]: [
        AosEventScenario.AirlineGroundHandling,
        AosEventScenario.ElectricityDistribution,
        AosEventScenario.InformationManagementItSystems,
        AosEventScenario.Maintenance,
        AosEventScenario.Media,
        AosEventScenario.WaterDistribution,
        AosEventScenario.SafetySecurity,
        AosEventScenario.OtherNotifications,
    ],
    [AosEventProcessType.Network]: scenarioMap(isAtc)[appConfig.theme],
    [AosEventProcessType.HelAirportConstruction]: [
        AosEventScenario.ApronConstruction,
        AosEventScenario.LandsideConstruction,
        AosEventScenario.TerminalConstruction,
    ],
})
