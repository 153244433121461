import Firefighters from 'aos-ui/src/assets/icons/firefighters.svg'
import NOTAM from 'aos-ui/src/assets/icons/NOTAM.svg'
import ShiftNote from 'aos-ui/src/assets/icons/shift_note.svg'
import SNOWTAM from 'aos-ui/src/assets/icons/SNOWTAM.svg'
import Tku from 'aos-ui-common/src/assets/icons/airports/aos/tku.svg'
import Tmp from 'aos-ui-common/src/assets/icons/airports/aos/tmp.svg'
import AircraftStands from 'aos-ui-common/src/assets/icons/scenarios/aircraft-stands.svg'
import AirfiledMaintanace from 'aos-ui-common/src/assets/icons/scenarios/airfiled-maintanace.svg'
import AirlineGroundHandling from 'aos-ui-common/src/assets/icons/scenarios/airline-ground-handling.svg'
import ApronBuses from 'aos-ui-common/src/assets/icons/scenarios/apron-buses.svg'
import ApronConstruction from 'aos-ui-common/src/assets/icons/scenarios/apron-construction.svg'
import Arrivals from 'aos-ui-common/src/assets/icons/scenarios/arrivals.svg'
import AtcReducedCapacity from 'aos-ui-common/src/assets/icons/scenarios/atc-reduced-capacity.svg'
import AutomatedBorderControl from 'aos-ui-common/src/assets/icons/scenarios/automated-border-control.svg'
import BaggageArrival from 'aos-ui-common/src/assets/icons/scenarios/baggage-arrival.svg'
import BaggageClaim from 'aos-ui-common/src/assets/icons/scenarios/baggage-claim.svg'
import BaggageDepartures from 'aos-ui-common/src/assets/icons/scenarios/baggage-departures.svg'
import BaggageGeneralInfo from 'aos-ui-common/src/assets/icons/scenarios/baggage-general-info.svg'
import BaggageHandling from 'aos-ui-common/src/assets/icons/scenarios/baggage-handling.svg'
import BaggageTransfer from 'aos-ui-common/src/assets/icons/scenarios/baggage-transfer.svg'
import BorderControl from 'aos-ui-common/src/assets/icons/scenarios/border-control.svg'
import Bus from 'aos-ui-common/src/assets/icons/scenarios/bus.svg'
import CheckIn from 'aos-ui-common/src/assets/icons/scenarios/check-in.svg'
import CheckinAutomat from 'aos-ui-common/src/assets/icons/scenarios/checkin-automat.svg'
import Construction from 'aos-ui-common/src/assets/icons/scenarios/construction.svg'
import DeIcing from 'aos-ui-common/src/assets/icons/scenarios/de-icing.svg'
import DivergedFlights from 'aos-ui-common/src/assets/icons/scenarios/diverged-flights.svg'
import Electricity from 'aos-ui-common/src/assets/icons/scenarios/electricity.svg'
import Elevators from 'aos-ui-common/src/assets/icons/scenarios/elevators.svg'
import Fog from 'aos-ui-common/src/assets/icons/scenarios/fog.svg'
import Gate from 'aos-ui-common/src/assets/icons/scenarios/gate.svg'
import Gates from 'aos-ui-common/src/assets/icons/scenarios/gates.svg'
import HeavyDelays from 'aos-ui-common/src/assets/icons/scenarios/heavy-delays.svg'
import Info from 'aos-ui-common/src/assets/icons/scenarios/info.svg'
import InfoManagement from 'aos-ui-common/src/assets/icons/scenarios/info-management.svg'
import LandSideConstruction from 'aos-ui-common/src/assets/icons/scenarios/land-side-construction.svg'
import Lightning from 'aos-ui-common/src/assets/icons/scenarios/lightning.svg'
import Lvp from 'aos-ui-common/src/assets/icons/scenarios/lvp.svg'
import Maintenance from 'aos-ui-common/src/assets/icons/scenarios/maintenance.svg'
import Media from 'aos-ui-common/src/assets/icons/scenarios/media.svg'
import NetworkGeneralInfo from 'aos-ui-common/src/assets/icons/scenarios/network-general-info.svg'
import Other from 'aos-ui-common/src/assets/icons/scenarios/other.svg'
import ParkingArea from 'aos-ui-common/src/assets/icons/scenarios/parking-area.svg'
import PassangerBridges from 'aos-ui-common/src/assets/icons/scenarios/passanger-bridges.svg'
import PassengerService from 'aos-ui-common/src/assets/icons/scenarios/passenger-service.svg'
import PassengerServices from 'aos-ui-common/src/assets/icons/scenarios/passenger-services.svg'
import PaxGeneralInfo from 'aos-ui-common/src/assets/icons/scenarios/pax-general-info.svg'
import Prm from 'aos-ui-common/src/assets/icons/scenarios/prm.svg'
import Rain from 'aos-ui-common/src/assets/icons/scenarios/rain.svg'
import RescueService from 'aos-ui-common/src/assets/icons/scenarios/rescue-service.svg'
import Roads from 'aos-ui-common/src/assets/icons/scenarios/roads.svg'
import Rwy from 'aos-ui-common/src/assets/icons/scenarios/rwy.svg'
import SafetySecurity from 'aos-ui-common/src/assets/icons/scenarios/safety-security.svg'
import SecurityGates from 'aos-ui-common/src/assets/icons/scenarios/security-gates.svg'
import Services from 'aos-ui-common/src/assets/icons/scenarios/services.svg'
import SlipperyApron from 'aos-ui-common/src/assets/icons/scenarios/slippery-apron.svg'
import Snow from 'aos-ui-common/src/assets/icons/scenarios/snow.svg'
import Taxi from 'aos-ui-common/src/assets/icons/scenarios/taxi.svg'
import Taxiways from 'aos-ui-common/src/assets/icons/scenarios/taxiways.svg'
import TerminalConstruction from 'aos-ui-common/src/assets/icons/scenarios/terminal-construction.svg'
import TerminalFacilities from 'aos-ui-common/src/assets/icons/scenarios/terminal-facilities.svg'
import TerminalPeakTimes from 'aos-ui-common/src/assets/icons/scenarios/terminal-peak-times.svg'
import TrafficStatus from 'aos-ui-common/src/assets/icons/scenarios/traffic-status.svg'
import Train from 'aos-ui-common/src/assets/icons/scenarios/train.svg'
import Transfer from 'aos-ui-common/src/assets/icons/scenarios/transfer.svg'
import TurnaroundGeneralInfo from 'aos-ui-common/src/assets/icons/scenarios/turnaround-general-info.svg'
import Wa from 'aos-ui-common/src/assets/icons/scenarios/wa.svg'
import WaterDistribution from 'aos-ui-common/src/assets/icons/scenarios/water-distribution.svg'
import Wind from 'aos-ui-common/src/assets/icons/scenarios/wind.svg'
import World from 'aos-ui-common/src/assets/icons/scenarios/world.svg'
import TaskAnimalBanishment from 'aos-ui-common/src/assets/icons/tasks/task-animal-banishment.svg'
import TaskAnimalConfirmation from 'aos-ui-common/src/assets/icons/tasks/task-animal-confirmation.svg'
import TaskAnimalKill from 'aos-ui-common/src/assets/icons/tasks/task-animal-kill.svg'
import TaskAnimalObservation from 'aos-ui-common/src/assets/icons/tasks/task-animal-observation.svg'
import TaskAnimals from 'aos-ui-common/src/assets/icons/tasks/task-animals.svg'
import TaskDeicing from 'aos-ui-common/src/assets/icons/tasks/task-deicing.svg'
import TaskHba from 'aos-ui-common/src/assets/icons/tasks/task-hba.svg'
import TaskInspectionAreaInspection from 'aos-ui-common/src/assets/icons/tasks/task-inspection-area-inspection.svg'
import TaskInspectionContractor from 'aos-ui-common/src/assets/icons/tasks/task-inspection-contractor.svg'
import TaskInspectionFireTruck from 'aos-ui-common/src/assets/icons/tasks/task-inspection-fire-truck.svg'
import TaskInspectionFod from 'aos-ui-common/src/assets/icons/tasks/task-inspection-fod.svg'
import TaskInspectionOther from 'aos-ui-common/src/assets/icons/tasks/task-inspection-other.svg'
import TaskInspectionOtherActors from 'aos-ui-common/src/assets/icons/tasks/task-inspection-other-actors.svg'
import TaskInspectionOtherAreas from 'aos-ui-common/src/assets/icons/tasks/task-inspection-other-areas.svg'
import TaskInspectionRope from 'aos-ui-common/src/assets/icons/tasks/task-inspection-rope.svg'
import TaskInspectionRunway from 'aos-ui-common/src/assets/icons/tasks/task-inspection-runway.svg'
import TaskInspectionTaxiway from 'aos-ui-common/src/assets/icons/tasks/task-inspection-taxiway.svg'
import TaskInspectionTerminal from 'aos-ui-common/src/assets/icons/tasks/task-inspection-terminal.svg'
import TaskLvp from 'aos-ui-common/src/assets/icons/tasks/task-lvp.svg'
import TaskLvpOutline from 'aos-ui-common/src/assets/icons/tasks/task-lvp-outline.svg'
import TaskMaintenance from 'aos-ui-common/src/assets/icons/tasks/task-maintenance.svg'
import TaskMaintenanceBrushes from 'aos-ui-common/src/assets/icons/tasks/task-maintenance-brushes.svg'
import TaskMaintenanceGeneral from 'aos-ui-common/src/assets/icons/tasks/task-maintenance-general.svg'
import TaskMaintenanceSummer from 'aos-ui-common/src/assets/icons/tasks/task-maintenance-summer.svg'
import TaskMaintenanceWinter from 'aos-ui-common/src/assets/icons/tasks/task-maintenance-winter.svg'
import TaskRescueService from 'aos-ui-common/src/assets/icons/tasks/task-rescue-service.svg'
import TaskSafetyInspection from 'aos-ui-common/src/assets/icons/tasks/task-safety-inspection.svg'

import { AirportIconsSvg } from '../../../../aos-ui-common/src/assets/icons/airports'
import Collapse from '../../assets/icons/actions/collapse.svg'
import Delete from '../../assets/icons/actions/delete.svg'
import DeleteRound from '../../assets/icons/actions/delete-round.svg'
import Expand from '../../assets/icons/actions/expand.svg'
import Arrow from '../../assets/icons/arrow.svg'
import Assignment from '../../assets/icons/assignment.svg'
import Atc from '../../assets/icons/atc.svg'
import Attachment from '../../assets/icons/attachment.svg'
import AutomaticFeed from '../../assets/icons/automatic-feed.svg'
import Back from '../../assets/icons/back.svg'
import Burger from '../../assets/icons/burger.svg'
import ChannelMail from '../../assets/icons/channels/channel-mail.svg'
import ChannelNotification from '../../assets/icons/channels/channel-notification.svg'
import ChannelPush from '../../assets/icons/channels/channel-push.svg'
import ChannelSms from '../../assets/icons/channels/channel-sms.svg'
import CheckCircle from '../../assets/icons/check-circle.svg'
import CheckWhite from '../../assets/icons/check-white.svg'
import CheckboxChecked from '../../assets/icons/checkbox-checked.svg'
import Checklist from '../../assets/icons/checklist.svg'
import Chevron from '../../assets/icons/chevron.svg'
import ChevronRight from '../../assets/icons/chevron-right.svg'
import Close from '../../assets/icons/close.svg'
import CloseEvent from '../../assets/icons/close-event.svg'
import Compass from '../../assets/icons/compass.svg'
import ConditionalIndicator from '../../assets/icons/conditional-indicator.svg'
import ContactList from '../../assets/icons/Contacts.svg'
import Content from '../../assets/icons/content.svg'
import CreateTask from '../../assets/icons/create-task.svg'
import CurrentTime from '../../assets/icons/current-time.svg'
import Dashboard from '../../assets/icons/dashboard.svg'
import Datapicker from '../../assets/icons/datapicker.svg'
import Default from '../../assets/icons/default.svg'
import Delay from '../../assets/icons/delay.svg'
import Departures from '../../assets/icons/departures.svg'
import Description from '../../assets/icons/description.svg'
import DismissNotification from '../../assets/icons/dismiss-notification.svg'
import Done from '../../assets/icons/done.svg'
import Download from '../../assets/icons/download.svg'
import DraggingHandle from '../../assets/icons/dragging-handle.svg'
import Drop from '../../assets/icons/drop.svg'
import EditDark from '../../assets/icons/edit-dark.svg'
import Emergency from '../../assets/icons/emergency.svg'
import Events from '../../assets/icons/events.svg'
import ExportChecklistTemplates from '../../assets/icons/export-checklist-templates.svg'
import FeedOutConfirmation from '../../assets/icons/feed-out-confirmation.svg'
import Flights from '../../assets/icons/flights.svg'
import Fold from '../../assets/icons/fold.svg'
import Forward from '../../assets/icons/forward.svg'
import ForwardFat from '../../assets/icons/forward-fat.svg'
import Gas from '../../assets/icons/gas.svg'
import Geolocation from '../../assets/icons/geolocation.svg'
import Help from '../../assets/icons/help.svg'
import Hourglass from '../../assets/icons/hourglass.svg'
import InfoCircle from '../../assets/icons/info-circle.svg'
import Invitation from '../../assets/icons/invitation.svg'
import Layers from '../../assets/icons/layers.svg'
import Location from '../../assets/icons/location.svg'
import LogOut from '../../assets/icons/log-out.svg'
import Mail from '../../assets/icons/mail.svg'
import Map from '../../assets/icons/map.svg'
import TrainDirection from '../../assets/icons/map-items/train-direction.svg'
import TrainInner from '../../assets/icons/map-items/train-inner.svg'
import TrainNoDirection from '../../assets/icons/map-items/train-no-direction.svg'
import TrainNumber from '../../assets/icons/map-items/train-number.svg'
import MarkReview from '../../assets/icons/mark-review.svg'
import Message from '../../assets/icons/message.svg'
import Messaging from '../../assets/icons/messaging.svg'
import More from '../../assets/icons/more.svg'
import MoreInfo from '../../assets/icons/more-info.svg'
import NoData from '../../assets/icons/no-data.svg'
import Notifications from '../../assets/icons/notifications.svg'
import OpenIn from '../../assets/icons/open-in.svg'
import OperationalForecastBags from '../../assets/icons/operational-forecast/operational-forecast-bags.svg'
import OperationalForecastNetwork from '../../assets/icons/operational-forecast/operational-forecast-network.svg'
import OperationalForecastPassaners from '../../assets/icons/operational-forecast/operational-forecast-passaners.svg'
import OperationalForecastTurnaround from '../../assets/icons/operational-forecast/operational-forecast-turnaround.svg'
import OtherRed from '../../assets/icons/other-red.svg'
import ParticipantAtAirport from '../../assets/icons/participant-at-airport.svg'
import ParticipantComing from '../../assets/icons/participant-coming.svg'
import ParticipantCommand from '../../assets/icons/participant-incident-command.svg'
import ParticipantLeft from '../../assets/icons/participant-left.svg'
import ParticipantObserving from '../../assets/icons/participant-observe.svg'
import ParticipantPending from '../../assets/icons/participant-pending.svg'
import ParticipantRemote from '../../assets/icons/participant-remote.svg'
import Pax from '../../assets/icons/pax.svg'
import Percent from '../../assets/icons/percent.svg'
import PickLocation from '../../assets/icons/pick-location.svg'
import ParkingCarReturn from '../../assets/icons/pictograms/parking/parking_CarReturn.svg'
import ParkingP from '../../assets/icons/pictograms/parking/parking_P.svg'
import ParkingP1 from '../../assets/icons/pictograms/parking/parking_P1.svg'
import ParkingP2 from '../../assets/icons/pictograms/parking/parking_P2.svg'
import ParkingP3 from '../../assets/icons/pictograms/parking/parking_P3.svg'
import ParkingP3Pika from '../../assets/icons/pictograms/parking/parking_P3_Pika.svg'
import ParkingP3Premium from '../../assets/icons/pictograms/parking/parking_P3-Premium.svg'
import ParkingP4A from '../../assets/icons/pictograms/parking/parking_P4A.svg'
import ParkingP4B from '../../assets/icons/pictograms/parking/parking_P4B.svg'
import ParkingP5 from '../../assets/icons/pictograms/parking/parking_P5.svg'
import ParkingP6 from '../../assets/icons/pictograms/parking/parking_P6.svg'
import ParkingP8 from '../../assets/icons/pictograms/parking/parking_P8.svg'
import TravelServicesArrivalServices from '../../assets/icons/pictograms/travelServices/travelServices_arrivalServices.svg'
import TravelServicesBaggageClaim1 from '../../assets/icons/pictograms/travelServices/travelServices_baggageClaim1.svg'
import TravelServicesBaggageClaim2A from '../../assets/icons/pictograms/travelServices/travelServices_baggageClaim2A.svg'
import TravelServicesBaggageClaim2B from '../../assets/icons/pictograms/travelServices/travelServices_baggageClaim2B.svg'
import TravelServicesCheckInDesks101114 from '../../assets/icons/pictograms/travelServices/travelServices_checkInDesks101114.svg'
import TravelServicesCheckInDesks201218 from '../../assets/icons/pictograms/travelServices/travelServices_checkInDesks201218.svg'
import TravelServicesCheckInDesks219232 from '../../assets/icons/pictograms/travelServices/travelServices_checkInDesks219232.svg'
import TravelServicesCheckInDesks240270 from '../../assets/icons/pictograms/travelServices/travelServices_checkInDesks240270.svg'
import TravelServicesCheckInKiosk from '../../assets/icons/pictograms/travelServices/travelServices_checkInKiosk.svg'
import TravelServicesCustoms from '../../assets/icons/pictograms/travelServices/travelServices_customs.svg'
import TravelServicesPassportControl from '../../assets/icons/pictograms/travelServices/travelServices_passportControl.svg'
import TravelServicesSecurityCheck from '../../assets/icons/pictograms/travelServices/travelServices_securityCheck.svg'
import WalkingRouteMarker from '../../assets/icons/pictograms/walking-route-marker.svg'
import Pictures from '../../assets/icons/pictures.svg'
import Pin from '../../assets/icons/pin.svg'
import Pinned from '../../assets/icons/pinned.svg'
import ArrivalsPlaceholder from '../../assets/icons/placeholders/arrivals-placeholder.svg'
import ChecklistPlaceholder from '../../assets/icons/placeholders/checklist-placeholder.svg'
import CreateMessageTemplatePlaceholder from '../../assets/icons/placeholders/create-message-template-placeholder.svg'
import CreateTaskPlaceholder from '../../assets/icons/placeholders/create-task-placeholder.svg'
import DeparturesPlaceholder from '../../assets/icons/placeholders/departures-placeholder.svg'
import InspectorPlaceholder from '../../assets/icons/placeholders/inspector-placeholder.svg'
import InvitePlaceholder from '../../assets/icons/placeholders/invite-placeholder.svg'
import MessagePlaceholder from '../../assets/icons/placeholders/message-placeholder.svg'
import PrivateInvitation from '../../assets/icons/placeholders/private-invitation.svg'
import UnitPlaceholder from '../../assets/icons/placeholders/unit-placeholder.svg'
import UserGroupPlaceholder from '../../assets/icons/placeholders/user-group-placeholder.svg'
import UserPlaceholder from '../../assets/icons/placeholders/user-placeholder.svg'
import Plus from '../../assets/icons/plus.svg'
import Private from '../../assets/icons/private.svg'
import PrivateClosed from '../../assets/icons/private-closed.svg'
import RestoreUser from '../../assets/icons/restore-user.svg'
import RotateLeft from '../../assets/icons/rotate-left.svg'
import RotateRight from '../../assets/icons/rotate-right.svg'
import Route from '../../assets/icons/route.svg'
import Save from '../../assets/icons/save.svg'
import Schedule from '../../assets/icons/schedule.svg'
import SearchFilters from '../../assets/icons/search-filters.svg'
import SearchWhite from '../../assets/icons/search-white.svg'
import SelectItem from '../../assets/icons/select-item.svg'
import Send from '../../assets/icons/send.svg'
import SendAlert from '../../assets/icons/send-alert.svg'
import Settings from '../../assets/icons/settings.svg'
import Share from '../../assets/icons/share.svg'
import ShowLess from '../../assets/icons/show-less.svg'
import ShowMore from '../../assets/icons/show-more.svg'
import Star from '../../assets/icons/star.svg'
import Error from '../../assets/icons/status/error.svg'
import Success from '../../assets/icons/status/success.svg'
import Warning from '../../assets/icons/status/warning.svg'
import WarningBold from '../../assets/icons/status/warning-bold.svg'
import StatusAtAirport from '../../assets/icons/status-at-airport.svg'
import StatusComing from '../../assets/icons/status-coming.svg'
import StatusRemote from '../../assets/icons/status-remote.svg'
import Target from '../../assets/icons/target.svg'
import Task from '../../assets/icons/task.svg'
import TaskRecurrence from '../../assets/icons/tasks/task-recurrence.svg'
import Timeline from '../../assets/icons/timeline.svg'
import ErrorToast from '../../assets/icons/toast/error.svg'
import InfoToast from '../../assets/icons/toast/info.svg'
import SuccessToast from '../../assets/icons/toast/success.svg'
import WarningToast from '../../assets/icons/toast/warning.svg'
import TrendArrowDown from '../../assets/icons/trend-arrow-down.svg'
import TrendArrowUp from '../../assets/icons/trend-arrow-up.svg'
import Unfold from '../../assets/icons/unfold.svg'
import UserThumbnail from '../../assets/icons/user-thumbnail.svg'
import Users from '../../assets/icons/users.svg'
import Visibility from '../../assets/icons/visibility.svg'
import ChartCbProbability from '../../assets/icons/weather-items/charts/chart-cb-probability.svg'
import ChartCloudBase from '../../assets/icons/weather-items/charts/chart-cloud-base.svg'
import ChartDewPoint from '../../assets/icons/weather-items/charts/chart-dew-point.svg'
import ChartFwp from '../../assets/icons/weather-items/charts/chart-fwp.svg'
import ChartLvp from '../../assets/icons/weather-items/charts/chart-lvp.svg'
import ChartRain from '../../assets/icons/weather-items/charts/chart-rain.svg'
import ChartSnow from '../../assets/icons/weather-items/charts/chart-snow.svg'
import ChartSnowRain from '../../assets/icons/weather-items/charts/chart-snow-rain.svg'
import ChartTemperature from '../../assets/icons/weather-items/charts/chart-temperature.svg'
import ChartTfwp from '../../assets/icons/weather-items/charts/chart-tfwp.svg'
import ChartThunderstorm from '../../assets/icons/weather-items/charts/chart-thunderstorm.svg'
import ChartTriangleDown from '../../assets/icons/weather-items/charts/chart-triangle-down.svg'
import ChartTriangleUp from '../../assets/icons/weather-items/charts/chart-triangle-up.svg'
import ChartWindDirection from '../../assets/icons/weather-items/charts/chart-wind-direction.svg'
import ChartWindGusts from '../../assets/icons/weather-items/charts/chart-wind-gusts.svg'
import ChartWindGusts12 from '../../assets/icons/weather-items/charts/chart-wind-gusts-12.svg'
import ChartWindSpeed from '../../assets/icons/weather-items/charts/chart-wind-speed.svg'
import Forecast from '../../assets/icons/weather-items/forecast.svg'
import WeatherLightingProbability from '../../assets/icons/weather-items/weather-lighting-probability.svg'
import WeatherLvp from '../../assets/icons/weather-items/weather-lvp.svg'
import WeatherNormal from '../../assets/icons/weather-items/weather-normal.svg'
import WeatherRain from '../../assets/icons/weather-items/weather-rain.svg'
import WeatherSnow from '../../assets/icons/weather-items/weather-snow.svg'
import WeatherSnowRain from '../../assets/icons/weather-items/weather-snow-rain.svg'
import WeatherTemperature from '../../assets/icons/weather-items/weather-temperature.svg'
import WeatherWindDirection from '../../assets/icons/weather-items/weather-wind-direction.svg'
import WeatherWindGusts from '../../assets/icons/weather-items/weather-wind-gusts.svg'
import WeatherWindSpeed from '../../assets/icons/weather-items/weather-wind-speed.svg'
import WindScale from '../../assets/icons/weather-items/wind-scale.svg'
import Window from '../../assets/icons/window.svg'
import ZoomIn from '../../assets/icons/zoom-in.svg'
import ZoomOut from '../../assets/icons/zoom-out.svg'

export const SvgIcon = {
    AircraftStands,
    AirfiledMaintanace,
    AirlineGroundHandling,
    ApronBuses,
    ApronConstruction,
    Arrivals,
    Rwy,
    AtcReducedCapacity,
    AutomatedBorderControl,
    AutomaticFeed,
    BaggageArrival,
    BaggageClaim,
    BaggageDepartures,
    BaggageGeneralInfo,
    BaggageHandling,
    BaggageTransfer,
    BorderControl,
    CheckCircle,
    ContactList,
    Bus,
    CheckIn,
    CheckinAutomat,
    Construction,
    DeIcing,
    DivergedFlights,
    Electricity,
    Elevators,
    Fog,
    Gate,
    Gates,
    Prm,
    HeavyDelays,
    Firefighters,
    InfoManagement,
    Info,
    InfoCircle,
    LandSideConstruction,
    Lightning,
    Lvp,
    Maintenance,
    Media,
    NOTAM,
    NetworkGeneralInfo,
    Other,
    ParkingArea,
    PassangerBridges,
    PassengerService,
    PassengerServices,
    PaxGeneralInfo,
    Rain,
    RescueService,
    Roads,
    Route,
    SafetySecurity,
    SecurityGates,
    Services,
    SlipperyApron,
    Snow,
    Taxi,
    Taxiways,
    TerminalConstruction,
    TerminalFacilities,
    TerminalPeakTimes,
    Tku,
    Tmp,
    TrafficStatus,
    Train,
    Transfer,
    TurnaroundGeneralInfo,
    Wa,
    WaterDistribution,
    Wind,
    ShiftNote,
    Plus,
    World,
    TaskAnimalBanishment,
    TaskAnimalConfirmation,
    TaskAnimalKill,
    TaskAnimalObservation,
    TaskAnimals,
    TaskDeicing,
    TaskHba,
    TaskInspectionAreaInspection,
    TaskInspectionContractor,
    TaskInspectionFireTruck,
    TaskInspectionFod,
    TaskInspectionOtherActors,
    TaskInspectionOtherAreas,
    TaskInspectionOther,
    TaskInspectionRope,
    TaskInspectionRunway,
    TaskInspectionTaxiway,
    TaskInspectionTerminal,
    TaskLvp,
    TaskLvpOutline,
    Pax,
    TaskMaintenanceBrushes,
    TaskMaintenanceGeneral,
    TaskMaintenanceSummer,
    TaskMaintenanceWinter,
    TaskMaintenance,
    TaskRescueService,
    TaskSafetyInspection,
    Collapse,
    DeleteRound,
    Delete,
    Expand,
    Arrow,
    Assignment,
    Back,
    Burger,
    ChannelMail,
    ChannelNotification,
    ChannelPush,
    ChannelSms,
    CheckWhite,
    Checklist,
    ChevronRight,
    Chevron,
    CloseEvent,
    Close,
    Compass,
    ConditionalIndicator,
    Content,
    CreateTask,
    CurrentTime,
    Dashboard,
    Datapicker,
    Delay,
    Departures,
    Description,
    DismissNotification,
    Done,
    DraggingHandle,
    Drop,
    EditDark,
    Emergency,
    Events,
    ExportChecklistTemplates,
    FeedOutConfirmation,
    Flights,
    Fold,
    ForwardFat,
    Forward,
    Geolocation,
    InfoToast,
    SuccessToast,
    ErrorToast,
    WarningToast,
    Help,
    Hourglass,
    Invitation,
    Layers,
    Location,
    LogOut,
    Mail,
    TrainDirection,
    TrainInner,
    TrainNoDirection,
    TrainNumber,
    Map,
    Message,
    Messaging,
    More,
    NoData,
    Notifications,
    OpenIn,
    OperationalForecastBags,
    OperationalForecastNetwork,
    OperationalForecastPassaners,
    OperationalForecastTurnaround,
    OtherRed,
    PickLocation,
    ParkingCarReturn,
    ParkingP,
    ParkingP1,
    ParkingP2,
    ParkingP3Premium,
    ParkingP3,
    ParkingP3Pika,
    ParkingP4A,
    ParkingP4B,
    ParkingP5,
    ParkingP6,
    ParkingP8,
    ParticipantAtAirport,
    ParticipantComing,
    ParticipantCommand,
    ParticipantObserving,
    ParticipantPending,
    ParticipantLeft,
    ParticipantRemote,
    Percent,
    TravelServicesArrivalServices,
    TravelServicesBaggageClaim1,
    TravelServicesBaggageClaim2A,
    TravelServicesBaggageClaim2B,
    TravelServicesCheckInDesks101114,
    TravelServicesCheckInDesks201218,
    TravelServicesCheckInDesks219232,
    TravelServicesCheckInDesks240270,
    TravelServicesCheckInKiosk,
    TravelServicesCustoms,
    TravelServicesPassportControl,
    TravelServicesSecurityCheck,
    WalkingRouteMarker,
    Pictures,
    Pin,
    Pinned,
    ArrivalsPlaceholder,
    ChecklistPlaceholder,
    CreateMessageTemplatePlaceholder,
    CreateTaskPlaceholder,
    DeparturesPlaceholder,
    InspectorPlaceholder,
    InvitePlaceholder,
    MessagePlaceholder,
    PrivateInvitation,
    UnitPlaceholder,
    UserGroupPlaceholder,
    UserPlaceholder,
    PrivateClosed,
    Private,
    RestoreUser,
    RotateLeft,
    RotateRight,
    Save,
    Schedule,
    SearchFilters,
    SearchWhite,
    SelectItem,
    Send,
    SendAlert,
    Settings,
    Share,
    ShowLess,
    SNOWTAM,
    ShowMore,
    Star,
    Error,
    Success,
    WarningBold,
    Warning,
    Target,
    Task,
    TaskRecurrence,
    Timeline,
    TrendArrowDown,
    TrendArrowUp,
    Unfold,
    UserThumbnail,
    Users,
    Visibility,
    ChartCbProbability,
    ChartCloudBase,
    ChartDewPoint,
    ChartFwp,
    ChartLvp,
    ChartRain,
    ChartSnowRain,
    ChartSnow,
    ChartTemperature,
    ChartTfwp,
    ChartThunderstorm,
    ChartTriangleDown,
    ChartTriangleUp,
    ChartWindDirection,
    ChartWindGusts12,
    ChartWindGusts,
    ChartWindSpeed,
    WeatherLightingProbability,
    WeatherLvp,
    WeatherNormal,
    WeatherRain,
    WeatherSnowRain,
    WeatherSnow,
    WeatherTemperature,
    WeatherWindDirection,
    WeatherWindGusts,
    WeatherWindSpeed,
    Forecast,
    WindScale,
    Window,
    Default,
    ZoomIn,
    ZoomOut,
    CheckboxChecked,
    Gas,
    Atc,
    MarkReview,
    StatusAtAirport,
    StatusComing,
    StatusRemote,
    MoreInfo,
    Attachment,
    Download,
    ...AirportIconsSvg,
}
