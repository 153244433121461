import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled from 'styled-components'

interface CircleIndicatorProps {
    variant: CircleIndicatorVariant
    large?: boolean
}

export const CircleIndicator: FCWithChildren<PropsWithChildren<CircleIndicatorProps>> = ({
    variant,
    large,
    children,
}) => {
    const [background, textColor] = variantsConfig[variant]
    return (
        <IndicatorBox bg={background}>
            <IndicatorText
                color={textColor}
                textAlign='center'
                size={13}
                paddingHorizontal={4}
                large={large}
            >
                {children}
            </IndicatorText>
        </IndicatorBox>
    )
}

export enum CircleIndicatorVariant {
    Blue,
    Yellow,
    Red,
}

const variantsConfig: Record<CircleIndicatorVariant, [Color, Color]> = {
    [CircleIndicatorVariant.Blue]: [Color.Primary, Color.SmartColor],
    [CircleIndicatorVariant.Yellow]: [Color.Yellow, Color.Black],
    [CircleIndicatorVariant.Red]: [Color.Red, Color.SmartColor],
}

const IndicatorBox = styled.div<{ bg: Color }>`
    background: ${p => p.bg};
    border-radius: 50%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &::before {
        display: block;
        content: '';
        padding-top: 100%;
    }
`

const IndicatorText = styled(Text)<{ large?: boolean }>`
    min-width: ${p => (p.large ? 30 : 20)}px;
`
