import { EnumValue } from 'aos-helpers/src/helpers/Enum'
import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import {
    changeTitleForSimpleCategory,
    clearingTitleOnCategoryChange,
} from 'aos-helpers/src/helpers/task-form/task-form'
import { minutesToTaskDuration } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { AttachmentsState } from 'aos-services/src/core/attachments/state'
import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import {
    AosSeverity,
    recurringTaskSeverities,
    taskSeverities,
    taskSeverityColorsMapping,
    taskSeverityTitle,
} from 'aos-services/src/services/common/types/AosSeverity'
import {
    isPersistentRecurrenceTaskOrInstance,
    isPersistentTaskOrInstance,
} from 'aos-services/src/services/tasks/types/payload/BaseTaskFormPayload'
import {
    TaskFormPayload,
    TaskPayloadValidation,
} from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import {
    taskCategories,
    TaskCategory,
    translateTaskCategory,
} from 'aos-services/src/services/tasks/types/TaskCategory'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import {
    TaskProcessType,
    taskProcessTypes,
} from 'aos-services/src/services/tasks/types/TaskProcessType'
import {
    TaskStatus,
    taskStatuses,
    translateTaskTitle,
} from 'aos-services/src/services/tasks/types/TaskStatus'
import { AttachmentContainer } from 'aos-ui/src/components/attachment/items/AttachmentContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { BorderedChip } from 'aos-ui/src/components/chip/BorderedChip'
import { LabeledCheckboxDropdown } from 'aos-ui/src/components/form/labeled/LabeledCheckboxDropdown'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledLocation } from 'aos-ui/src/components/form/labeled/LabeledLocation'
import { LabeledProcessType } from 'aos-ui/src/components/form/labeled/LabeledProcessType'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { LabeledSeverity } from 'aos-ui/src/components/form/labeled/LabeledSeverity'
import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import { LabeledTimeRange } from 'aos-ui/src/components/form/labeled/LabeledTimeRange'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { IconRadioRenderer } from 'aos-ui/src/components/form/radio/IconRadioRenderer'
import { TimePickerControlled } from 'aos-ui/src/components/form/timePicker/TimePickerControlled'
import { TwoColumnFormContainer } from 'aos-ui/src/components/form/ui/TwoColumnFormContainer'
import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'
import { Color } from 'aos-ui-common/src/styles/Color'
import { taskIcon } from 'aos-ui-task/src/components/task/TaskIcon'
import React, { FC, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { DetailedTaskForm } from './DetailedTaskForm'
import { TaskAssigneesAutocomplete } from './TaskAssigneesAutocomplete'
import { TaskRecurrence } from './taskForms/partials/TaskRecurrence'

interface TaskFormProps {
    task: TaskFormPayload
    form: FormValidation<TaskPayloadValidation>
    attachmentsState: AttachmentsState
    metadata: TaskMetadata
    statusReadOnly?: boolean
    checklists: AosTaskChecklistTemplate[]

    changeForm(t: TaskFormPayload): void
    setViewMode(n: DialogView): void
    attachmentsAction(a: AttachmentsAction): void
}

export const TaskForm: FC<TaskFormProps> = ({
    task,
    setViewMode,
    form,
    metadata,
    attachmentsState,
    attachmentsAction,
    changeForm,
    statusReadOnly,
    checklists,
}) => {
    const severityUpdate = (severity: AosSeverity) => {
        if (severity === AosSeverity.Emergency) {
            const processType = TaskProcessType.Daily
            partialFormUpdate({ processType, severity })
        } else {
            partialFormUpdate({ severity })
        }
    }
    const partialFormUpdate = (value: Partial<TaskFormPayload>) => {
        changeForm({ ...task, ...value } as TaskFormPayload)
    }
    const canEditRecurrence = !task.parentTaskId
    const canChangeCategory = !isPersistentTaskOrInstance(task)
    const canSwitchRecurrence = !isPersistentRecurrenceTaskOrInstance(task)

    const prevTask = usePrevious(task)

    useEffect(() => {
        if (canChangeCategory && prevTask) {
            clearingTitleOnCategoryChange(prevTask, task, changeForm)
            changeTitleForSimpleCategory(prevTask, task, changeForm)
        }
    }, [task, prevTask])

    useEffect(() => {
        if (task.severity === AosSeverity.Emergency) {
            partialFormUpdate({ recurrence: undefined })
        }
    }, [task.severity])

    return (
        <TwoColumnFormContainer
            mainContent={
                <>
                    <LabeledSeverity
                        keyPrefix='tasks.form.severity'
                        seleniumLocation='severity'
                        value={task.severity}
                        severities={
                            task.parentTaskId || task.recurrence
                                ? recurringTaskSeverities
                                : taskSeverities
                        }
                        translateSeverity={taskSeverityTitle}
                        isError={form.error.severity}
                        colorsMapping={taskSeverityColorsMapping}
                        onChange={severity => severityUpdate(severity)}
                        isRequired
                    />
                    <LabeledProcessType
                        isRequired
                        isError={form.error.processType}
                        seleniumLocation='processType'
                        value={task.processType}
                        processTypes={taskProcessTypes}
                        keyPrefix='tasks.form.process-type'
                        onChange={processType => partialFormUpdate({ processType })}
                        severity={task.severity}
                    />
                    <LabeledFormElement
                        label={translate('tasks.form.category.label')}
                        isRequired
                        isError={form.error.category}
                        seleniumLocation='category'
                    >
                        <BlockRadioGroup
                            items={taskCategories}
                            value={task.category}
                            marginBottom={8}
                            gridSize={3}
                            disabled={!canChangeCategory}
                            Renderer={({ isSelected, item, onSelect, disabled }) => (
                                <IconRadioRenderer
                                    isSelected={isSelected}
                                    label={translateTaskCategory(item)}
                                    onSelect={onSelect}
                                    disabled={disabled}
                                    icon={taskIcon[item]}
                                    color={Color.ChartBase}
                                />
                            )}
                            onChange={category => partialFormUpdate({ category })}
                        />
                    </LabeledFormElement>

                    <LabeledFormElement
                        label={translate('tasks.form.assignees.label')}
                        marginBottom={12}
                    >
                        <TaskAssigneesAutocomplete
                            value={task.assignees}
                            onChange={assignees => partialFormUpdate({ assignees })}
                        />
                    </LabeledFormElement>
                    <DetailedTaskForm
                        form={form}
                        payload={task}
                        metadata={metadata}
                        changeForm={partialFormUpdate}
                    />
                    <LabeledInput
                        keyPrefix='tasks.form.title'
                        marginBottom={12}
                        onChangeText={title => partialFormUpdate({ title })}
                        value={task.title}
                        type='text'
                        isRequired
                        isError={form.error.title}
                    />
                    <LabeledTextArea
                        keyPrefix='tasks.form.description'
                        marginBottom={12}
                        onChangeText={description => partialFormUpdate({ description })}
                        value={task.description}
                        resizeable
                    />

                    <LabeledCheckboxDropdown
                        marginBottom={12}
                        preventEmpty={false}
                        partialContent={(items: AosTaskChecklistTemplate[]) =>
                            items.length > 0
                                ? translate('tasks.form.checklists.selected', {
                                      count: items.length,
                                  })
                                : translate('tasks.form.checklists.placeholder')
                        }
                        valueRenderer={(item: AosTaskChecklistTemplate) => (
                            <Box row justify='space-between' flex={1}>
                                <Text>{item.name}</Text>
                                <BorderedChip weight='medium' width={80}>
                                    {item.tag}
                                </BorderedChip>
                            </Box>
                        )}
                        items={checklists}
                        value={
                            task.checklistsTemplateIds?.map(id =>
                                checklists.find(q => q.id === id),
                            ) || []
                        }
                        keyPrefix='tasks.form.checklists'
                        allContent={translate('tasks.form.checklists.all')}
                        onChange={(checklists: AosTaskChecklistTemplate[]) =>
                            partialFormUpdate({ checklistsTemplateIds: checklists.map(c => c.id) })
                        }
                    />
                </>
            }
            sideContent={
                <>
                    {statusReadOnly ? (
                        <LabeledInput
                            marginBottom={16}
                            keyPrefix='tasks.form.status'
                            value={translateTaskTitle(task.status as EnumValue)}
                            readOnly
                        />
                    ) : (
                        <LabeledSelect
                            isError={form.error.status}
                            isRequired
                            labelRenderer={translateTaskTitle}
                            items={taskStatuses.filter(s =>
                                task.category === TaskCategory.LVP ? s !== TaskStatus.OnHold : true,
                            )}
                            marginBottom={16}
                            keyPrefix='tasks.form.status'
                            onChange={(status: TaskStatus) => partialFormUpdate({ status })}
                            value={task.status}
                        />
                    )}
                    {task.status === TaskStatus.Done &&
                        task.severity !== AosSeverity.Maintenance && (
                            <TimePickerControlled
                                keyPrefix='tasks.form.spend-time'
                                isRequired
                                isError={form.error.spendTimeInMinutes}
                                onChange={taskDuration =>
                                    partialFormUpdate({
                                        spendTimeInMinutes:
                                            (taskDuration?.hour || 0) * 60 +
                                            (taskDuration?.minute || 0),
                                    })
                                }
                                value={
                                    task.spendTimeInMinutes
                                        ? minutesToTaskDuration(task.spendTimeInMinutes)
                                        : undefined
                                }
                            />
                        )}
                    {canEditRecurrence && (
                        <TaskRecurrence
                            disabled={task.status !== TaskStatus.Todo}
                            task={task}
                            changeForm={partialFormUpdate}
                            recurrenceChangeable={canSwitchRecurrence}
                            errors={form.error.recurrence}
                            recurrenceScheduleError={form.error.recurrenceSchedule}
                        />
                    )}
                    <LabeledTimeRange
                        keyPrefix={
                            task.recurrence !== null
                                ? 'tasks.form.scheduling-recurrence'
                                : 'tasks.form.scheduling'
                        }
                        marginBottom={16}
                        seleniumLocation='scheduling'
                        value={{
                            startTime: task.startTime,
                            endTime: task.endTime,
                        }}
                        onChange={partialFormUpdate}
                        isError={form.error.endTimeEmpty || form.error.endTime}
                        errorSuffix={form.error.endTimeEmpty ? '.not-empty' : ''}
                        isRequired
                        isEndTimeRelatedToStartTime
                    />
                    <LabeledLocation
                        marginBottom={16}
                        value={task.location}
                        keyPrefix='tasks.form.location'
                        onChange={location => {
                            partialFormUpdate({ location: location })
                        }}
                        onOpenOnMap={() => setViewMode(DialogView.Map)}
                        isRequired={task.category === TaskCategory.Animals}
                        isError={form.error.location}
                    />
                    <LabeledFormElement
                        label={translate('tasks.form.attachments.label')}
                        marginBottom={12}
                    >
                        <AttachmentContainer
                            attachmentsState={attachmentsState}
                            attachmentsAction={attachmentsAction}
                            editable
                            gridSize={2}
                        />
                    </LabeledFormElement>
                </>
            }
        />
    )
}
