import { MapLayer } from 'aos-map-engine/src/Types'
import { Feature } from 'geojson'
import { Dictionary, fromPairs } from 'lodash'

import { SelectedMapElementType } from '../map/types/SelectedMapElement'
import { BaggageClaimPictogramCategory } from './properties/BaggageClaimProperties'

export type CustomLayerHandlers = {
    [key: string]: (config: MapLayer) => Feature[]
}

export const customLayerHandlers: CustomLayerHandlers = {
    loadBaggageClaim: (config: MapLayer): Feature[] => {
        return config.geojson!.features!.map(
            feature =>
                ({
                    ...feature,
                    properties: {
                        ...feature.properties,
                        belt: baggageClaimPictogramToBeltMapping[feature.properties?.name],
                        selectTarget: SelectedMapElementType.BaggageClaim,
                    },
                } as Feature),
        )
    },
    loadGates: (config: MapLayer): Feature[] => {
        return config!.geojson!.features!.map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                Gate: feature.properties?.label,
                selectTarget: SelectedMapElementType.Gate,
            },
        }))
    },
    loadBusGates: (config: MapLayer): Feature[] => {
        return config!.geojson!.features!.map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                Gate: feature.properties?.label,
                selectTarget: SelectedMapElementType.Gate,
            },
        }))
    },
    loadStands: (config: MapLayer): Feature[] => {
        return config!.geojson!.features!.map((feature: Feature) => ({
            ...feature,
            properties: {
                ...feature.properties,
                teksti: feature.properties?.tunnus, // mobile map backward compatibility
                selectTarget: SelectedMapElementType.Stand,
                pictogram: 'FA_Pictogram_Series_' + feature.properties?.tunnus + '_Stand',
            },
        }))
    },
}

const baggageClaimPictogramToBeltMapping: Dictionary<string> = fromPairs([
    [BaggageClaimPictogramCategory.One, '1'],
    [BaggageClaimPictogramCategory.TwoA, '2A'],
    [BaggageClaimPictogramCategory.TwoB, '2B'],
])
