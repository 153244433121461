import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { Text } from '../base/Text'

interface HeaderFilterItemProps {
    selected: boolean
    variant?: HeaderFilterVariant
    onClick(): void
}

export const HeaderFilterItem: FCWithChildren<HeaderFilterItemProps> = ({
    selected,
    variant = HeaderFilterVariant.Large,
    children,
    onClick,
}) => {
    const fontSize = variant === HeaderFilterVariant.Large ? 18 : 14
    const fontColor = selected ? Color.SmartColor : Color.Black
    return (
        <Text
            fullHeight
            centered
            cursor='pointer'
            onClick={onClick}
            weight='light'
            size={fontSize}
            color={fontColor}
        >
            {children}
        </Text>
    )
}

export enum HeaderFilterVariant {
    Large = 1,
    Small,
}
