import { literal } from 'aos-helpers/src/helpers/Type'
import { appConfig } from 'appConfig'

export type AosEventScenarioIcon = (typeof AosEventScenarioIcon)[keyof typeof AosEventScenarioIcon]

const AirportIcons: Record<string, any> = {
    AOS: {
        Uti: literal('Uti'),
        Vaa: literal('Vaa'),
        Vrk: literal('Vrk'),
        Tku: literal('Tku'),
        Tmp: literal('Tmp'),
        Svl: literal('Svl'),
        Sjy: literal('Sjy'),
        Rvn: literal('Rvn'),
        Prm: literal('Prm'),
        Hel: literal('Hel'),
        Hem: literal('Hem'),
        Hyv: literal('Hyv'),
        Lpp: literal('Lpp'),
        Ivl: literal('Ivl'),
        Joe: literal('Joe'),
        Jyv: literal('Jyv'),
        Kaj: literal('Kaj'),
        Kao: literal('Kao'),
        Kem: literal('Kem'),
        Kev: literal('Kev'),
        Kok: literal('Kok'),
        Ktt: literal('Ktt'),
        Kuo: literal('Kuo'),
        Mhq: literal('Mhq'),
        Mik: literal('Mik'),
        Oul: literal('Oul'),
    },
    AOIMS: {
        Eka: literal('Eka'),
        Eke: literal('Eke'),
        Eku: literal('Eku'),
        Epu: literal('Epu'),
        Eru: literal('Eru'),
        Tay: literal('Tay'),
        Tll: literal('Tll'),
    },
}

export const AosEventScenarioIcon = {
    AircraftStands: literal('AircraftStands'),
    AirfiledMaintanace: literal('AirfiledMaintanace'),
    AirlineGroundHandling: literal('AirlineGroundHandling'),
    ApronBuses: literal('ApronBuses'),
    ApronConstruction: literal('ApronConstruction'),
    Arrivals: literal('Arrivals'),
    AtcReducedCapacity: literal('AtcReducedCapacity'),
    AutomatedBorderControl: literal('AutomatedBorderControl'),
    BaggageArrival: literal('BaggageArrival'),
    BaggageClaim: literal('BaggageClaim'),
    BaggageDepartures: literal('BaggageDepartures'),
    BaggageGeneralInfo: literal('BaggageGeneralInfo'),
    BaggageHandling: literal('BaggageHandling'),
    BaggageTransfer: literal('BaggageTransfer'),
    BorderControl: literal('BorderControl'),
    Bus: literal('Bus'),
    CheckIn: literal('CheckIn'),
    CheckinAutomat: literal('CheckinAutomat'),
    Construction: literal('Construction'),
    DeIcing: literal('DeIcing'),
    DivergedFlights: literal('DivergedFlights'),
    Electricity: literal('Electricity'),
    Elevators: literal('Elevators'),
    Enf: literal('Enf'),
    Fog: literal('Fog'),
    Gates: literal('Gates'),
    HeavyDelays: literal('HeavyDelays'),
    InfoManagement: literal('InfoManagement'),
    Info: literal('Info'),
    LandSideConstruction: literal('LandSideConstruction'),
    Lightning: literal('Lightning'),
    Lvp: literal('Lvp'),
    Maintenance: literal('Maintenance'),
    Media: literal('Media'),
    NetworkGeneralInfo: literal('NetworkGeneralInfo'),
    Other: literal('Other'),
    ParkingArea: literal('ParkingArea'),
    PassangerBridges: literal('PassangerBridges'),
    PassengerService: literal('PassengerService'),
    PassengerServices: literal('PassengerServices'),
    PaxGeneralInfo: literal('PaxGeneralInfo'),
    Por: literal('Por'),
    Rain: literal('Rain'),
    RescueService: literal('RescueService'),
    Roads: literal('Roads'),
    Rwy: literal('Rwy'),
    SafetySecurity: literal('SafetySecurity'),
    SecurityGates: literal('SecurityGates'),
    Services: literal('Services'),
    SlipperyApron: literal('SlipperyApron'),
    Snow: literal('Snow'),
    Taxi: literal('Taxi'),
    Taxiways: literal('Taxiways'),
    TerminalConstruction: literal('TerminalConstruction'),
    TerminalFacilities: literal('TerminalFacilities'),
    TerminalPeakTimes: literal('TerminalPeakTimes'),
    TrafficStatus: literal('TrafficStatus'),
    Train: literal('Train'),
    Transfer: literal('Transfer'),
    TurnaroundGeneralInfo: literal('TurnaroundGeneralInfo'),
    Wa: literal('Wa'),
    WaterDistribution: literal('WaterDistribution'),
    Wind: literal('Wind'),
    World: literal('World'),
    ...AirportIcons[appConfig.theme],
}
