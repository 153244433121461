import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { MarginBoxProps, marginStyleGenerator } from 'aos-ui/src/components/base/Box'
import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'
import { boxShadow } from 'aos-ui/src/components/base/Theme'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { Color } from 'aos-ui-common/src/styles/Color'
import { Property } from 'csstype'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { CSSObject } from 'styled-components'

import { Icon } from '../svg/Icon'

export interface IconWhiteButtonProps extends ButtonProps, SvgProps, IconBoxWrapperProps {
    type?: 'submit' | 'reset' | 'button'
    tabIndex?: number
}

interface IconBoxWrapperProps extends MarginBoxProps {
    variant?: IconBoxButtonVariant
    cursor?: Property.Cursor
    disabled?: boolean
}

export enum IconBoxButtonVariant {
    White,
    Blue,
    Grey,
}

const variantConfig: Record<IconBoxButtonVariant, CSSObject> = {
    [IconBoxButtonVariant.White]: {
        'background': Color.White,
        'color': Color.Black,
        'boxShadow': boxShadow.std,
        ':hover': {
            color: Color.Primary,
        },
    },
    [IconBoxButtonVariant.Blue]: {
        'background': Color.Primary,
        'color': Color.White,
        'boxShadow': boxShadow.std,
        ':hover': {
            background: Color.PrimaryDark,
            color: Color.White,
        },
    },
    [IconBoxButtonVariant.Grey]: {
        background: Color.White,
        color: Color.Grey2,
        boxShadow: '0 0px 5px 0 rgba(0,0,0,0.1)',
    },
}

export const IconBoxButton: FCWithChildren<PropsWithChildren<IconWhiteButtonProps>> = ({
    onClick,
    disabled,
    id,
    type = 'button',
    tabIndex,
    children,
    seleniumLocation,
    svg,
    variant,
    ...rest
}) => (
    <IconBoxButtonWrapper
        id={id}
        type={type}
        onClick={onClick}
        disabled={disabled}
        data-test-id={seleniumLocation}
        tabIndex={tabIndex}
        variant={variant}
        {...rest}
    >
        <Icon block svg={svg} iconSize={BlockSize.Std} />
        {children}
    </IconBoxButtonWrapper>
)

const IconBoxButtonWrapper = styled(Button)<IconBoxWrapperProps>(
    ({ variant = IconBoxButtonVariant.White, cursor, disabled, ...rest }) => {
        const margins = marginStyleGenerator(rest)
        const styles: CSSObject = {
            cursor: cursor || 'pointer',
            padding: '6px',
            borderRadius: '5px',
        }

        if (disabled) {
            styles.opacity = 0.3
        }

        return { ...styles, ...margins, ...variantConfig[variant] }
    },
)
