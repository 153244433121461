import { mapEngine } from 'aos-map-engine/src'
import React, { FC, PropsWithChildren, useMemo } from 'react'

import { SingleMapProps } from '../MapProps'
import { BimMap } from '../openlayers/BimMap'
import { BimMapLayer } from '../openlayers/layers/BimMapLayer'
import { OSMLayer } from '../openlayers/layers/OSMLayer'
import { OpenlayersMap } from '../openlayers/OpenlayersMap'

export const WorldMap: FC<PropsWithChildren<SingleMapProps>> = props => {
    const { onBoundsChanged, children, siteLocation } = props
    const loadMapLayers = useMemo(
        () =>
            mapEngine
                .getLayersForCurrentAirport(siteLocation)
                .map(layer => (
                    <BimMapLayer
                        mapLayerId={layer.id}
                        layerId={layer.name}
                        key={layer.id}
                        isVisible
                        zIndex={layer.zIndex}
                    />
                )),
        [],
    )

    return (
        <OpenlayersMap initialBoundsSet={onBoundsChanged}>
            <OSMLayer zIndex={1} />
            <BimMap>{loadMapLayers}</BimMap>
            {children}
        </OpenlayersMap>
    )
}
