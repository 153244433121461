import {
    AosSeverity,
    taskSeverityColorsMapping,
} from 'aos-services/src/services/common/types/AosSeverity'
import {
    AosEventScenario,
    eventScenarioIcon,
} from 'aos-services/src/services/events/types/AosEventScenario'
import {
    hasTrainDirection,
    RingRailTrainMapProperties,
} from 'aos-services/src/services/layerData/properties/RingRailTrainMapProperties'
import { MapRestriction } from 'aos-services/src/services/map/types/MapRestriction'
import { RestrictionResourceType } from 'aos-services/src/services/restrictions/types/RestrictionResourceType'
import { TaskCategory } from 'aos-services/src/services/tasks/types/TaskCategory'
import { svgIconForName } from 'aos-ui/src/components/svg/Icon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import { taskIcon } from 'aos-ui-task/src/components/task/TaskIcon'
import React, { ReactElement } from 'react'

import { ShadowStyle, SvgPngConverter } from '../../../helpers/SvgPngConverter'
import { svgToDataUrl } from '../../../helpers/SvgRenderer'
import { BusDoorIcon } from './BusDoorIcon'
import { MarkerMapIcon } from './EventMapIcon'
import { MarkerMapIconRenderer } from './EventMapIconRenderer'
import { MarkerMapIconSelectedRenderer } from './EventMapIconSelectedRenderer'
import { GateIcon } from './GateIcon'
import { IconHeight } from './MapFeatureIconBuilder'
import { PictogramSelectedRenderer } from './PictogramSelectedRenderer'
import { RestrictionIcon } from './RestrictionIcon'
import { StandIcon } from './StandIcon'
import { TrainBackgroundIconRenderer } from './TrainBackgroundIconRenderer'
import { TrainLabelIcon } from './TrainLabelIcon'

const iconCache: { [key: string]: string } = {}

const pictogramShadowConfig = {
    shadowOffsetX: 0,
    shadowOffsetY: 2,
    shadowColor: 'rgba(0,0,0,.4)',
    shadowBlur: 2,
}

const getOrCachePng = (
    cacheKey: string,
    componentGetter: f.Func0<ReactElement<any>>,
    height?: number,
    shadowStyle?: ShadowStyle,
) => {
    if (iconCache[cacheKey]) {
        return Promise.resolve(iconCache[cacheKey])
    } else {
        const svgConverter = new SvgPngConverter()
        return svgConverter.svgToPng(svgToDataUrl(componentGetter()), height, shadowStyle)
    }
}

export const renderEventIcon = (props: {
    severity: AosSeverity
    category: typeof AosEventScenario
}) =>
    getOrCachePng(`${props.severity}_${props.category}`, () => (
        <MarkerMapIconRenderer
            severity={props.severity}
            icon={svgIconForName(eventScenarioIcon(props.category))}
        />
    ))

export const renderFodIcon = (props: { discoveryDate: string }) => {
    return getOrCachePng(`fod-${props.discoveryDate}`, () => (
        <MarkerMapIcon color={Color.PrimaryLight} iconColor={Color.White}>
            <SvgImage style={{ fill: Color.White }} svg={svgIconForName('TaskInspectionFod')} />
        </MarkerMapIcon>
    ))
}

export const renderTaskIcon = (props: { severity: AosSeverity; category: TaskCategory }) =>
    getOrCachePng(`${props.severity}_${props.category}`, () => (
        <MarkerMapIconRenderer
            colorMapping={taskSeverityColorsMapping}
            severity={props.severity}
            icon={taskIcon[props.category]}
        />
    ))

export const renderTrainBackgroundIcon = (train: RingRailTrainMapProperties) => {
    const withDirection = hasTrainDirection(train)
    return getOrCachePng(
        `train_bg_${train.trainNumber}_${withDirection ? 'direction' : 'no_direction'}`,
        () => (
            <TrainBackgroundIconRenderer
                scheduleStatus={train.scheduleStatus}
                withDirection={withDirection}
            />
        ),
    )
}

export const renderTrainOverlayIcon = (train: RingRailTrainMapProperties) => {
    const withDirection = hasTrainDirection(train)
    return getOrCachePng(`train_overlay_${withDirection ? 'direction' : 'no_direction'}`, () => (
        <TrainBackgroundIconRenderer withDirection={withDirection} isSelected />
    ))
}

export const renderTrainLabelIcon = (train: RingRailTrainMapProperties) =>
    getOrCachePng(`${train.trainNumber}`, () => (
        <TrainLabelIcon>{`${train.trainNumber}${train.commuterLine}`}</TrainLabelIcon>
    ))

export const renderStandIcon = (standNumber: string) =>
    getOrCachePng(`stand_${standNumber}`, () => <StandIcon text={standNumber} />, IconHeight.Stand)

export const renderBusDoorIcon = (num: number) =>
    getOrCachePng(`busDoor_${num}`, () => <BusDoorIcon door={num} />, 40)

export const renderGateIcon = (gateId: string, height: number) =>
    getOrCachePng(`gate_${gateId}`, () => <GateIcon text={gateId} />, height)

export const renderRestrictionIcon = (resource: string, height: number, splitLabel: boolean) =>
    getOrCachePng(
        `restriction_${resource}`,
        () => <RestrictionIcon text={resource} splitText={splitLabel} />,
        height,
    )

export const standRestrictionIconRenderer = (restrictions: MapRestriction[]) => {
    const standRestrictions = restrictions.filter(
        restriction => restriction.resourceType === RestrictionResourceType.Stand,
    )

    return (standNumber: string) =>
        standRestrictions.some(restriction => restriction.resourceNumber === standNumber)
            ? renderRestrictionIcon(standNumber, IconHeight.Stand, false)
            : renderStandIcon(standNumber)
}

export const gateRestrictionIconRenderer = (restrictions: MapRestriction[]) => {
    const gateRestrictions = restrictions.filter(
        restriction =>
            restriction.resourceType === RestrictionResourceType.Gate ||
            restriction.resourceType === RestrictionResourceType.BusGate,
    )

    return (gateId: string, height: number) =>
        gateRestrictions.some(restriction => restriction.resourceNumber === gateId)
            ? renderRestrictionIcon(gateId, height, true)
            : renderGateIcon(gateId, height)
}

export const renderSvgIcon = (
    component: Svg,
    height?: number,
    shadowStyle?: ShadowStyle,
): Promise<string> =>
    getOrCachePng(
        `${height}_${window.btoa(component.toString())}`,
        () => React.createElement(component, { xmlns: 'http://www.w3.org/2000/svg' }),
        height,
        shadowStyle,
    )

export const renderSvgIconWithShadow = (component: Svg, height?: number): Promise<string> =>
    renderSvgIcon(component, height, pictogramShadowConfig)

export const renderMarkerMapOverlayIcon = () =>
    getOrCachePng('event-overlay', () => <MarkerMapIconSelectedRenderer />)

export const renderSvgIconOverlayIcon = (width: number, height: number) => {
    const additionalSpace = SvgPngConverter.additionalSpaceForStyle(pictogramShadowConfig)
    return getOrCachePng(
        'pictogram-overlay',
        () => (
            <PictogramSelectedRenderer
                width={width - 2 * additionalSpace}
                height={height - 2 * additionalSpace}
            />
        ),
        undefined,
        pictogramShadowConfig,
    )
}
