import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import { toast, ToastOptions } from 'react-toastify'

import { Box } from '../base/Box'
import { Text } from '../base/Text'
import { Button } from '../buttons/Button'
import { Icon } from '../svg/Icon'
import { SvgIcon } from '../svg/SvgIcon'

export const successToast = (message: string, callback?: () => void, options?: ToastOptions) => {
    toast.success(
        <Box row justify='space-between'>
            <Text size={14} paddingLeft={8} color={Color.ToastFont}>
                {message}
            </Text>

            {callback && (
                <Button
                    style={{ paddingLeft: 8 }}
                    onClick={() => {
                        callback()
                        toast.dismiss()
                    }}
                >
                    <Text color={Color.ToastGoToIssueLabelColor} size={14}>
                        {translate('toast.go-to-issue')}
                    </Text>
                </Button>
            )}
        </Box>,
        {
            icon: <Icon iconSize={BlockSize.Std} color={Color.Green} svg={SvgIcon.SuccessToast} />,
            ...options,
        },
    )
}

export const errorToast = (message: string, options?: ToastOptions) => {
    toast.error(
        <Text size={14} paddingLeft={8} color={Color.ToastFont}>
            {message}
        </Text>,
        {
            icon: <Icon iconSize={BlockSize.Std} color={Color.Red} svg={SvgIcon.ErrorToast} />,
            ...options,
        },
    )
}

interface WarningLeaveToastProps {
    onCancel: () => void
    onLeave: () => void
}

export const warningToast = (
    message: string,
    description?: string,
    actions?: WarningLeaveToastProps,
    options?: ToastOptions,
) => {
    toast.warning(
        <Box row justify='space-between'>
            <Box column>
                <Text size={14} paddingLeft={8} color={Color.ToastFont}>
                    {message}
                </Text>
                {description && (
                    <Text size={12} paddingLeft={8} color={Color.ToastFont}>
                        {description}
                    </Text>
                )}
            </Box>
            {actions && (
                <Box columnGap={8} row>
                    <Button
                        style={{ paddingLeft: 8 }}
                        onClick={() => {
                            actions.onCancel()
                            toast.dismiss()
                        }}
                    >
                        <Text weight='medium' color={Color.Black} size={14}>
                            {translate('toast.cancel')}
                        </Text>
                    </Button>
                    <Button
                        style={{ paddingLeft: 8 }}
                        onClick={() => {
                            actions.onLeave()
                            toast.dismiss()
                        }}
                    >
                        <Text weight='medium' color={Color.Red} size={14}>
                            {translate('toast.leave')}
                        </Text>
                    </Button>
                </Box>
            )}
        </Box>,
        {
            icon: <Icon iconSize={BlockSize.Std} color={Color.Orange} svg={SvgIcon.WarningToast} />,
            style: { background: Color.VeryPaleOrange },
            hideProgressBar: true,
            autoClose: false,
            closeButton: !actions,
            ...options,
        },
    )
}

export const infoToast = (message: string, callback?: () => void, options?: ToastOptions) => {
    toast.info(
        <Box row justify='space-between'>
            <Text size={14} paddingLeft={8} color={Color.ToastFont}>
                {message}
            </Text>

            {callback && (
                <Button
                    style={{ paddingLeft: 8 }}
                    onClick={() => {
                        callback()
                        toast.dismiss()
                    }}
                >
                    <Text color={Color.ToastGoToIssueLabelColor} size={14}>
                        {translate('toast.go-to-issue')}
                    </Text>
                </Button>
            )}
        </Box>,
        {
            icon: (
                <Icon
                    iconSize={BlockSize.Std}
                    color={Color.InfoToastIconColor}
                    svg={SvgIcon.InfoToast}
                />
            ),
            progressStyle: { background: Color.IntensiveBlue, opacity: 1 },
            style: { background: Color.LightBlue },
            ...options,
        },
    )
}
