import { MapLayer } from 'aos-map-engine/src/Types'
import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../core/state'
import { FloorButtons } from './FloorButtons'
import { TerminalLayersVisibilityCategory } from './TerminalLayersVisibilityCategory'
import { WorldLayersVisibilityCategory } from './WorldLayersVisibilityCategory'

export const LayersVisibilitySidebar: FC<LayersVisibilitySidebarProps> = ({
    bimMapLayers,
    userLayersVisibility,
    isTooltip,
    setBimLayersVisibility,
    setFloorNumber,
    mapVariant,
}) => {
    const variant = mapVariant ?? useSelector((state: State) => state.airportMap.variant)

    return (
        <SidebarBox column bg={Color.White}>
            {variant === MapVariant.MainAirport && (
                <FloorButtons
                    selectedFloor={userLayersVisibility.floor}
                    setFloorNumber={setFloorNumber}
                />
            )}
            <Box flex={1}>
                {isTooltip ? (
                    <DarkScrollbar autoHeight autoHeightMax={500}>
                        <Box paddingHorizontal={24} fullHeight>
                            {variant === MapVariant.MainAirport ? (
                                <TerminalLayersVisibilityCategory
                                    bimMapLayers={bimMapLayers}
                                    bimMapLayersVisibility={userLayersVisibility.list}
                                    setBimLayersVisibility={setBimLayersVisibility}
                                />
                            ) : (
                                <WorldLayersVisibilityCategory
                                    bimMapLayersVisibility={userLayersVisibility.list}
                                    setBimLayersVisibility={setBimLayersVisibility}
                                />
                            )}
                        </Box>
                    </DarkScrollbar>
                ) : (
                    <DarkScrollbar>
                        <Box paddingHorizontal={24} fullHeight>
                            {variant === MapVariant.MainAirport ? (
                                <TerminalLayersVisibilityCategory
                                    bimMapLayers={bimMapLayers}
                                    bimMapLayersVisibility={userLayersVisibility.list}
                                    setBimLayersVisibility={setBimLayersVisibility}
                                />
                            ) : (
                                <WorldLayersVisibilityCategory
                                    bimMapLayersVisibility={userLayersVisibility.list}
                                    setBimLayersVisibility={setBimLayersVisibility}
                                />
                            )}
                        </Box>
                    </DarkScrollbar>
                )}
            </Box>
        </SidebarBox>
    )
}

const SidebarBox = styled(Box)`
    width: 360px;
    flex-shrink: 0;
`

interface LayersVisibilitySidebarProps {
    mapVariant?: MapVariant
    bimMapLayers: MapLayer[]
    isTooltip?: boolean
    userLayersVisibility: UserLayerVisibilityState
    setBimLayersVisibility(v: string[]): void
    setFloorNumber(v: number): void
}
