import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'
import { capitalize, entries, reduce } from 'lodash'

import { MapSiteLocationCustomization } from '../../common/types/MapSiteLocationCustomization'

const airportsJson = require('siteLocation.json') as AirportConfig

type AirportConfig = {
    airports: {
        [key: string]: AirportDetails
    }
}
export const typedAirports = airportsJson
export const AosAirport = reduce(
    typedAirports.airports,
    (acc, _, key) => {
        acc[key] = key
        return acc
    },
    {} as Record<string, string>,
)
export type AosAirport = keyof typeof AosAirport

export const MainAirport = entries(typedAirports.airports).find(
    q => q[1].mainAirport,
)?.[0] as AosAirport
export interface AirportDetails {
    iata: string
    icao: string
    name: string
    city: string
    country: string
    latitude: number
    longitude: number
    mainAirport?: boolean // Optional because not all airports might have this field
}

export const allAirports = EnumValues.getValues<AosAirport>(AosAirport)

export const locationName = translateEnum<AosAirport>(AosAirport, 'airports')

export const airportIcon = (e: AosAirport) => capitalize((e as string).toLowerCase())

export const toSiteLocationCustomization = (aosAirport: AosAirport): MapSiteLocationCustomization =>
    aosAirport === MainAirport
        ? MapSiteLocationCustomization.MainAirport
        : MapSiteLocationCustomization.Other
