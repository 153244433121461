import { DashboardItemMode } from 'aos-services/src/services/statusDashboard/types/DashboardItemMode'
import { Positioned } from 'aos-ui/src/components/base/Positioned'
import { CircleIndicator, CircleIndicatorVariant } from 'aos-ui/src/components/ui/CircleIndicator'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import { generatePath } from 'react-router'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { Link } from '../../../core/Links'
import { DashboardTabStatus } from '../../../core/statusDashboard/selectors'
import {
    StatusDashboardTab,
    translateTab,
} from '../../../services/statusDashboard/types/DashboardPreset'

interface StatusDashboardLinkProps {
    tab: StatusDashboardTab
    status?: DashboardTabStatus
    onClick(v: StatusDashboardTab): void
}

export const StatusDashboardLink: FC<StatusDashboardLinkProps> = props => {
    const { tab, onClick, status } = props
    const getCircleVariant = (mode: DashboardItemMode) =>
        mode === DashboardItemMode.Alert
            ? CircleIndicatorVariant.Red
            : CircleIndicatorVariant.Yellow

    return (
        <StyledNavLink
            to={generatePath(Link.StatusDashboardPreset, { preset: tab })}
            activeClassName='current'
            onClick={() => onClick(tab)}
            exact
        >
            {translateTab(tab)}
            {status && !!status.count && (
                <Positioned top={-6} right={-10}>
                    <CircleIndicator variant={getCircleVariant(status.mode)}>
                        {status.count}
                    </CircleIndicator>
                </Positioned>
            )}
        </StyledNavLink>
    )
}

const StyledNavLink = styled(NavLink)`
    position: relative;
    padding: 1rem;
    margin-right: 1.25rem;
    font-weight: 400;
    font-size: 1.125rem;
    &.current {
        color: ${Color.TabBarActive};
    }
    color: ${Color.TabBarInactive};
`
