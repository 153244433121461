import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { loadBimLayersDataAction } from 'aos-services/src/core/bimLayersData/actions'
import { loadLayersDataAction } from 'aos-services/src/core/layersData/actions'
import { loadUserLayerVisibilityAction } from 'aos-services/src/core/userLayersVisibility/actions'
import { atcGridPositionExtent, atcMapFitConfig } from 'aos-services/src/services/map/mapPositions'
import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'
import { MapStack } from 'aos-ui-map/src/components/map/MapStack'
import { BimMapRef } from 'aos-ui-map/src/components/map/openlayers/BimMap'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    addAtcLayerAction,
    eventModalsMapParentAction,
    removeAtcLayerAction,
} from '../../../core/eventModals/actions'
import { State } from '../../../core/state'

export const EventMap = () => {
    const dispatch = useDispatch()
    const mapRef = useRef<BimMapRef>(null)
    const siteLocation = useSelector(currentUserSiteLocation)
    const mapState = useSelector((state: State) => state.eventModals.map)
    const isApoc = useSelector((state: State) => state.auth.currentUser.isApoc())
    const layersData = useSelector((state: State) => state.layersData)
    const userLayerVisibility = useSelector((state: State) => state.eventMapUserLayerVisibility)
    const bimLayersState = useSelector((state: State) => state.bimLayers)
    const currentEvent = useSelector((state: State) => state.eventModals.currentEvent)
    const atcActiveLayers = useSelector((state: State) => state.eventModals.currentAtcLayers)

    const onSelectedLayer = (layer: BimLayerName) => {
        if (atcActiveLayers.findIndex(q => q === layer) > -1) {
            dispatch(removeAtcLayerAction(layer))
        } else {
            dispatch(addAtcLayerAction(layer))
        }
    }

    useEffect(() => {
        if (mapRef.current?.map) {
            mapRef.current.map.once('rendercomplete', () => {
                mapRef.current?.map.getView().fit(atcGridPositionExtent, {
                    size: mapRef.current?.map.getSize(),
                    ...atcMapFitConfig,
                    padding: [80, 0, -300, 0],
                })
            })
        }
    }, [mapRef.current?.map])

    return (
        <MapStack
            siteLocation={siteLocation}
            ref={mapRef}
            isApoc={isApoc}
            mapState={{
                ...mapState,
                atcLayerControl: !!currentEvent.atcAlert,
                userLayerVisibility: {
                    ...userLayerVisibility,
                    list: [...userLayerVisibility.list, ...atcActiveLayers],
                },
            }}
            layersData={layersData}
            bimLayersState={bimLayersState}
            userLayerVisibility={userLayerVisibility}
            loadLayersDataAction={arg => dispatch(loadLayersDataAction(arg))}
            mapAction={arg => dispatch(eventModalsMapParentAction(arg))}
            loadBimLayersDataAction={() => dispatch(loadBimLayersDataAction())}
            loadUserLayerVisibilityAction={() => dispatch(loadUserLayerVisibilityAction())}
            selectLayerAction={onSelectedLayer}
        />
    )
}
