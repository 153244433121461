import { enumToName } from 'aos-helpers/src/helpers/Enum'
import { translateEnum, translateParts } from 'aos-helpers/src/helpers/translations/Translations'

import { AosEventProcessType } from './types/AosEventProcessType'
import { AosEventScenario } from './types/AosEventScenario'
import { AosEventVisibility } from './types/AosEventVisibility'

export const eventVisibilityTitle = (e: AosEventVisibility) =>
    translateEnum<AosEventVisibility>(AosEventVisibility, 'event-visibility')(e)

export const eventProcessTitle = (e: AosEventProcessType) =>
    translateEnum<AosEventProcessType>(AosEventProcessType, 'event-process')(e)

export const eventScenarioTitle = (e: AosEventScenario) =>
    translateEnum<typeof AosEventScenario>(AosEventScenario, 'event-scenario')(e)

export const eventScenarioTitleTemplate = (e: typeof AosEventScenario) =>
    translateParts('event-scenario', enumToName(AosEventScenario)(e), 'title-template')

export const channelTitle = (t: string) => translateParts('feed-out', t)
